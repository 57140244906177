exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".message___R5X25 {\n  vertical-align: middle;\n  padding-left: 10px;\n}\n", "", {"version":3,"sources":["C:/agent3/vsts-agent-win-x64-2.153.2/_work/2276/s/src/Crayon.EndCustomerPortal/ClientApp/src/components/Alert/Alert.module.less"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;CACpB","file":"Alert.module.less","sourcesContent":[".message {\n  vertical-align: middle;\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"message": "message___R5X25"
};