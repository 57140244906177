export const expandIconToLeftOverride = {
  '& > div:first-child > div': {
    paddingRight: 0,
    paddingLeft: 32,
  },
  '& > div[role="button"]': {
    left: 8,
    right: 'auto',
  },
  '& > [disabled]': {
    opacity: 1,
  }
}

export const tableHeaderOverride = {
  background: '#fff',
  color: '#a0a0a0',
  '& > div': {
    opacity: '1 !important',
  }
};

export const inputOverride = {
  width: 76,
  margin: '0 !important',
  marginRight: '5px !important',
  '& input': {
    padding: 12,
  }
};

export const loadingButtonOverride = {
  width: '20px!important',
  height: '20px!important',
}

export const expansionDetailsOverride = {
  padding: '30px 0 10px 0',
  backgroundColor: '#f8f8f8',
}

export const detailContainer = {
  paddingLeft: 56,
  paddingRight: 24,
}

export const tooltip = {
  fontFamily: 'OpenSans',
  fontSize: 12,
  boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
}
