import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';

const styles = {
  buttonOverride: {
    height: 42,
    textTransform: 'none',
    minWidth: 50,
    '& [role=presentation]': {
      width: '20px !important',
      height: '20px !important',
    }
  },
  primary: {
    backgroundColor: '#3071ad',
    borderColor: '#3071ad',
    '& > span': {
      color: 'white',
      '& > svg': {
        fill: 'white',
      }

    },
    '&:hover': {
      backgroundColor: '#265887',
    },
    '&:disabled': {
      backgroundColor: 'white',
      borderColor: '#eee',
      '& > span': {
        color: '#bbb',
        '& > svg': {
          fill: '#bbb',
        }
      }
    },
  },

  warning: {
    backgroundColor: '#c13b43',
    borderColor: '#c13b43',
    '& > span': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#9d2c33',
    },
  },

  secondary: {
    backgroundColor: 'white',
    borderColor: '#ddd',
    '& > span': {
      color: 'black',
    },
    '&:hover': {
      backgroundColor: '#eee',
    },
    '&:disabled': {
      backgroundColor: 'white',
      '& > span': {
        color: '#a0a0a0',
      }
    },
  },


};

const CustomButton = (props) => {
  const {
    children,
    classes,
    type,
    ...other
  } = props;
  let typeClass;
  switch (type) {
    case 'primary':
      typeClass = classes.primary;
      break;
    case 'warning':
      typeClass = classes.warning;
      break;
    case 'secondary':
      typeClass = classes.secondary;
      break;
    default:
      typeClass = classes.primary;
      break;
  }
  return (
    <Button
      variant="outlined"
      className={`${classes.buttonOverride} ${typeClass}`}
      {...other}
    >
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
}

export default withStyles(styles)(CustomButton);
