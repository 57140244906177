import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withTranslations } from './../../hocs/translations';
import { withStyles } from '@material-ui/core/styles';

import ProfileMenu from './ProfileMenu';

import {
    active,
    logotype,
    logoWrapper,
    headerStyles,
    menuItems,
    desktopLogoMenu,
    mobileMenuStyles,
} from './Header.module.less';

const styles = {
    appBarStyleOverride: {
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
    },
}

function ListItemLink({ url, text, toggleNavigation, exact }) {
    return (
        <ListItem onClick={toggleNavigation} button component={NavLink} to={url} activeClassName={active} exact={exact}>
            <ListItemText primary={text} />
        </ListItem>
    )
}

ListItemLink.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    toggleNavigation: PropTypes.func.isRequired,
}

function Header(props) {
    const {
        isNavigationOpen,
        toggleNavigation,
        toggleProfileMenu,
        logo,
        profileMenuAnchor,
        userId,
        role,
        translations,
        isAuthorized,
        classes
    } = props;

    const logoImage = (
        logo
            ? <img className={logotype} alt="Logo" src={logo} />
            : null
    );

    return (
        <AppBar position="static" color="default" className={classes.appBarStyleOverride}>

            <Toolbar className={headerStyles}>
                {isAuthorized &&
                    ([
                        <Hidden key="mobileToggle" smUp>
                            <IconButton onClick={toggleNavigation} color="inherit" aria-label="Menu">
                                <MenuIcon />
                            </IconButton>
                            {logoImage}
                        </Hidden>,
                        <Hidden key="desktopMenu" xsDown>
                            <div className={desktopLogoMenu}>
                                <div className={logoWrapper}>
                                    {logoImage}
                                </div>
                                <div className={menuItems}>
                                    <NavLink to="/" exact activeClassName={active}>{translations.home}</NavLink>
                                    <NavLink to="/subscriptions" activeClassName={active}>{translations.subscriptions}</NavLink>
                                    {/* <NavLink to="/reports" activeClassName={active}>{translations.reports}</NavLink> */}
                                    <NavLink to="/users" activeClassName={active}>{translations.users}</NavLink>
                                </div>
                            </div>
                        </Hidden>
                    ])
                }
                <ProfileMenu toggleProfileMenu={toggleProfileMenu} isAuthorized={isAuthorized} profileMenuAnchor={profileMenuAnchor} role={role} userId={userId} />
            </Toolbar>
            <Hidden smUp>
                <Drawer open={isNavigationOpen} onClose={toggleNavigation}>
                    <List className={mobileMenuStyles}>
                        <ListItemLink url="/" exact text="Home" toggleNavigation={toggleNavigation} />
                        <ListItemLink url="/subscriptions" text="Subscriptions" toggleNavigation={toggleNavigation} />
                        {/* <ListItemLink url="/reports" text="Reports" toggleNavigation={toggleNavigation} /> */}
                        <ListItemLink url="/users" text="Users" toggleNavigation={toggleNavigation} />
                    </List>
                </Drawer>
            </Hidden>
        </AppBar>
    );
}

Header.propTypes = {
    profileMenuAnchor: PropTypes.object,
    isNavigationOpen: PropTypes.bool.isRequired,
    toggleProfileMenu: PropTypes.func.isRequired,
    toggleNavigation: PropTypes.func.isRequired,
    logo: PropTypes.string.isRequired,
    userId: PropTypes.string,
    role: PropTypes.string,
    translations: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
        appBarStyleOverride: PropTypes.string.isRequired
    }).isRequired,
};

export default compose(
    withStyles(styles),
    withTranslations,
)(Header);
