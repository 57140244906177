import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import SubscriptionSummary from './SubscriptionSummary';

import { withStyles } from '@material-ui/core/styles';

import {
  addonRow,
} from './Subscriptions.module.less';

import {
  detailContainer,
} from './SubscriptionsMaterialUiOverrides'

const styles = {
  detailContainer,
}

const Addon = (props) => {
  const {
    quantity,
    handleUpdateQuantity,
    submitUpdateQuantity,
    addon,
    enableUpdate,
    classes,
  } = props;
  return (
    <Grid className={addonRow} item xs={12}>
      <SubscriptionSummary
        detailClass={classes.detailContainer}
        quantity={quantity}
        enableUpdate={enableUpdate}
        handleUpdateQuantity={handleUpdateQuantity}
        submitUpdateQuantity={submitUpdateQuantity}
        subscription={addon}
        isAddon
      />
    </Grid>
  );
};

Addon.propTypes = {
  addon: PropTypes.shape({

  }).isRequired,
  handleUpdateQuantity: PropTypes.func.isRequired,
  submitUpdateQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.string.isRequired,
  enableUpdate: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Addon);
