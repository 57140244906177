import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTracking } from 'react-appinsights';
import { withTranslations } from './../../hocs/translations';
import * as subscriptionsActionCreators from './../../store/subscriptions/subscriptionsActions';
import * as alertsActionCreators from './../../store/alerts/alertsActions';

import { Subscriptions } from './../../components';

export class SubscriptionsContainer extends Component {
  static propTypes = {
    items: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    handleFetchSubscriptions: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
  }
  static defaultProps = {
     items: [],
  }

  componentDidMount() {
    const {
      items,
      handleFetchSubscriptions,
      isFetching,
      translations,
    } = this.props;
    document.title = `${translations.title} - ${translations.subscriptions}`;
    if(!items.length && !isFetching) {
      handleFetchSubscriptions();
    }
  }

  render() {
    const {
      isFetching,
      error,
      items
    } = this.props;
    
    return (
      <Subscriptions
        isFetching={isFetching}
        subscriptions={items}
        error={error}
      />
    );
  }
}

const mapStateToProps = ({ subscriptions }) => {
  return {
    items: subscriptions.items,
    isFetching: subscriptions.isFetching,
    error: subscriptions.error,
  }
} 
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...subscriptionsActionCreators,
      ...alertsActionCreators
    },
    dispatch
  );
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTracking,
  withTranslations,
)(SubscriptionsContainer);