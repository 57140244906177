import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { AddonContainer } from './../../containers';
import SubscriptionSummary from './SubscriptionSummary';

import { withStyles } from '@material-ui/core/styles';
import { withTranslations } from './../../hocs/translations';

import { formatDate } from './../../helpers/utils';

import {
    listTitle,
    borderRight,
    marginTop,
} from './Subscriptions.module.less';

import {
    expandIconToLeftOverride,
    expansionDetailsOverride,
    detailContainer,
} from './SubscriptionsMaterialUiOverrides'

const styles = {
    expandIconToLeftOverride,
    expansionDetailsOverride,
    detailContainer,
}

const Subscription = (props) => {
    const {
        subscription,
        expanded,
        quantity,
        enableUpdate,
        handleExpandPanelChange,
        handleUpdateQuantity,
        submitUpdateQuantity,
        openErrorModal,
        translations,
        classes
    } = props;
    return (
        <ExpansionPanel
            expanded={expanded}
            onChange={() => handleExpandPanelChange(subscription.id)}
            key={subscription.id}
        >
            <ExpansionPanelSummary className={classes.expandIconToLeftOverride} expandIcon={<ExpandMoreIcon />}>
                <SubscriptionSummary
                    quantity={quantity}
                    enableUpdate={enableUpdate}
                    handleUpdateQuantity={handleUpdateQuantity}
                    submitUpdateQuantity={submitUpdateQuantity}
                    subscription={subscription}
                />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionDetailsOverride}>
                <Grid container>
                    <Grid container className={classes.detailContainer} alignItems="stretch">
                        <Grid item xs={3} className={`${borderRight}`}>
                            <strong className={listTitle}>{translations.subscription}</strong>
                            <ul>
                                <li>{`${translations.id}: ${subscription.id}`}</li>
                                <li>{`${translations.createdDate}: ${formatDate(subscription.createdDate)}`}</li>
                            </ul>
                        </Grid>
                        <Grid item xs={3} className={`${borderRight}`}>
                            <strong className={listTitle}>{translations.cloudAccount}</strong>
                            <ul>
                                <li>{`${translations.id}: ${subscription.cloudAccountId}`}</li>
                                <li>{`${translations.name}: ${subscription.cloudAccountName}`}</li>
                                <li>{`${translations.domain}: ${subscription.domain}`}</li>
                            </ul>
                        </Grid>
                        {
                            subscription.isModernNCE ? null :
                                ([<Grid item xs={3} className={`${borderRight}`} key={subscription.id +'grid1'}>
                                <strong className={listTitle}>{translations.microsoftAgreement}</strong>
                                {
                                    subscription.fetchingCloudAgreement
                                        ? <CircularProgress />
                                        : (
                                            <ul>
                                                <li>{subscription.cloudAgreement.dateAgreed ? formatDate(subscription.cloudAgreement.dateAgreed) : '-'}</li>
                                                <li>{subscription.cloudAgreement.firstName || '-'}  {subscription.cloudAgreement.lastName || '-'}</li>
                                                <li>{subscription.cloudAgreement.email || '-'}</li>
                                                <li>{subscription.cloudAgreement.phone || '-'}</li>
                                                <li>
                                                    {
                                                        subscription.cloudAgreement.link
                                                            ? <a target="_blank" rel="noopener noreferrer" href={subscription.cloudAgreement.link}>{translations.readMicrosoftAgreement}</a>
                                                            : '-'
                                                    }
                                                </li>
                                            </ul>
                                        )
                                }
                                </Grid>,
                                    <Grid item xs={3} className={`${borderRight}`} key={subscription.id +'grid2'}>
                                <strong className={listTitle}>{translations.usageAndInvoiceDetails}</strong>
                                {
                                    subscription.fetchingBillingInfo
                                        ? <CircularProgress />
                                        : (
                                            <ul>
                                                <li>{subscription.billingInfo.firstName || '-'}  {subscription.billingInfo.lastName || '-'}</li>
                                                <li>{subscription.billingInfo.street || '-'}</li>
                                                <li>{subscription.billingInfo.zipCode || '-'}, {subscription.billingInfo.city || '-'}</li>
                                                <li>{subscription.billingInfo.region || '-'}</li>
                                                <li>{subscription.billingInfo.usageCountry || '-'}</li>
                                            </ul>
                                        )
                                }
                            </Grid>])
                        }
                    </Grid>
                    {
                        subscription.listOfAddons.length
                            ? ([
                                <Grid key="gridTitle" item xs={12} className={classes.detailContainer}>
                                    <strong className={`${listTitle} ${marginTop}`}>
                                        {translations.addOns}
                                    </strong>
                                </Grid>,
                                subscription.listOfAddons.map((addon) => (
                                    <AddonContainer key={addon.id} openErrorModal={openErrorModal} addon={addon} subscriptionId={subscription.id} />
                                ))
                            ])
                            : null
                    }

                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

Subscription.propTypes = {
    subscription: PropTypes.shape({

    }).isRequired,
    handleUpdateQuantity: PropTypes.func.isRequired,
    submitUpdateQuantity: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    quantity: PropTypes.string.isRequired,
    enableUpdate: PropTypes.bool.isRequired,
    handleExpandPanelChange: PropTypes.func.isRequired,
    openErrorModal: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles),
    withTranslations,
)(Subscription);