import { generateRandomString, parseJwt, queryStringToObject } from './utils';
import store from './../store/configureStore';

const baseUrl = window.location.origin;

export function hasToken() {
  return !!store.getState().users.userCredentials.access_token
}

export function isAuthed() {
  if(hasToken()) {
    return isTokenValid();
  }
  return false;
}

function isTokenValid() {
  const access_token = parseJwt(store.getState().users.userCredentials.access_token);
  const invalidConsumerId = !access_token.consumerId && !(access_token.tenantId && sessionStorage.getItem('requestedConsumerId'))
  if(access_token.scope.indexOf('EndCustomerPortalApi') === -1 || invalidConsumerId) {
    return false;
  }
  return true
}

export function goToSignIn(path) {
  const { signInUrl } = store.getState().config.config; 
  const client_id = 'CrayonEndCustomerPortal';
  const response_type = encodeURIComponent('id_token token');
  const redirect_uri = encodeURIComponent(`${baseUrl}/callback`);
  const scope = encodeURIComponent('openid profile EndCustomerPortalApi');
  const state = generateRandomString();
  const nonce = generateRandomString();
  localStorage.clear();
  localStorage.setItem(nonce, path);

  var consumerId = queryStringToObject(window.location.search.substring(1)).consumerId;
  if(!!consumerId) {
    sessionStorage.setItem('requestedConsumerId', consumerId);
  }
  window.location.href = `${signInUrl}/auth/connect/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&nonce=${nonce}`;
}

export function logout() {
  const { signInUrl } = store.getState().config.config;
  const signedOutTitle = store.getState().localization.translations.signedOutTitle;
  const signedOutText = store.getState().localization.translations.signedOutText;
  sessionStorage.setItem('translations', JSON.stringify({
    signedOutTitle,
    signedOutText,
  }));
  const idToken = store.getState().users.userCredentials.id_token;
  const post_logout_redirect_uri = encodeURIComponent(`${baseUrl}/logout`);
  window.location.href = `${signInUrl}/auth/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${post_logout_redirect_uri}`;
}

export function endSessionAndCloseTab() {
  sessionStorage.removeItem('requestedConsumerId');
  window.close();
  if(!window.closed) {
    window.location.href = `/logout`;
  }
}
