import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_ERROR,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_ADDON,
  UPDATE_ADDON_SUCCESS,
  ASSIGN_SUBSCRIPTION_CLOUD_AGREEMENT,
  ASSIGN_SUBSCRIPTION_BILLING_INFO,
  SET_FETCH_CLOUD_AGREEMENT,
  SET_FETCH_BILLING_INFO,
} from './../../constants/actionConstants';

import {
  fetchSubscriptions,
  updateSubscription,
  fetchCloudAgreement,
  fetchBillingInfo,
} from './../../helpers/api';


function beginFetchSubscriptions() {
  return {
    type: FETCH_SUBSCRIPTIONS,
  }
}

function fetchSubscriptionsSuccess(subscriptions) {
  return {
    type: FETCH_SUBSCRIPTIONS_SUCCESS,
    subscriptions,
  }
}

function fetchSubscriptionsError(error) {
  return {
    type: FETCH_SUBSCRIPTIONS_ERROR,
    error,
  }
}

function beginUpdateSubscription(id) {
  return {
    type: UPDATE_SUBSCRIPTION,
    id,
  }
}

function updateSubscriptionSuccess(id, quantity) {
  return {
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    id,
    quantity,
  }
}

function updateSubscriptionError(id) {
  return {
    type: UPDATE_SUBSCRIPTION_ERROR,
    id,
  }
}

function beginUpdateAddon(subId, addonId) {
  return {
    type: UPDATE_ADDON,
    subId,
    addonId,
  }
}

function updateAddonSuccess(subId, addonId, quantity) {
  return {
    type: UPDATE_ADDON_SUCCESS,
    subId,
    addonId,
    quantity,
  }
}

function updateAddonError(subId, addonId) {
  return {
    type: UPDATE_SUBSCRIPTION_ERROR,
    subId,
    addonId,
  }
}

function assignCloudAgreement(id, cloudAgreement) {
  return {
    type: ASSIGN_SUBSCRIPTION_CLOUD_AGREEMENT,
    id,
    cloudAgreement,
  }
}

function assignBillingInfo(id, billingInfo) {
  return {
    type: ASSIGN_SUBSCRIPTION_BILLING_INFO,
    id,
    billingInfo,
  }
}

function setFetchBillingInfo(id) {
  return {
    type: SET_FETCH_BILLING_INFO,
    id,
  }
}

function setFetchCloudAgreement(id) {
  return {
    type: SET_FETCH_CLOUD_AGREEMENT,
    id,
  }
}

export function handleFetchCloudAgreement(subscriptionId, cloudAccountId) {
  return (dispatch) => {
    dispatch(setFetchCloudAgreement(cloudAccountId));
    return fetchCloudAgreement(subscriptionId)
      .then(cloudAgreement => dispatch(assignCloudAgreement(cloudAccountId, cloudAgreement)))
      .catch(() => dispatch(assignCloudAgreement(cloudAccountId, {})))
  }
}

export function handleFetchBillingInfo(subscriptionId, cloudAccountId) {
  return (dispatch) => {
    dispatch(setFetchBillingInfo(cloudAccountId));
    return fetchBillingInfo(subscriptionId)
      .then(billingInfo => dispatch(assignBillingInfo(cloudAccountId, billingInfo)))
      .catch(() => dispatch(assignBillingInfo(cloudAccountId, {})))
  }
}

export function handleUpdateAddon(subscriptionId, addonId, quantity) {
  return (dispatch) => {
    dispatch(beginUpdateAddon(subscriptionId, addonId))
    return updateSubscription(addonId, quantity)
      .then(() => dispatch(updateAddonSuccess(subscriptionId, addonId, quantity)))
      .catch((error) => {
        dispatch(updateAddonError(subscriptionId, addonId));
        throw error;
      })
  }
}

export function handleUpdateSubscription(subscriptionId, quantity) {
  return (dispatch) => {
    dispatch(beginUpdateSubscription(subscriptionId))
    return updateSubscription(subscriptionId, quantity)
      .then(() => dispatch(updateSubscriptionSuccess(subscriptionId, quantity)))
      .catch((error) => {
        dispatch(updateSubscriptionError(subscriptionId));
        throw error;
      })
  }
}

export function handleFetchSubscriptions() {
  return (dispatch) => {
    dispatch(beginFetchSubscriptions());
    return fetchSubscriptions()
      .then(Subscriptions => dispatch(fetchSubscriptionsSuccess(Subscriptions.items)))
      .catch(error => dispatch(fetchSubscriptionsError(error)));
  }
}
