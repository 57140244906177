import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_ERROR,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_ADDON,
  UPDATE_ADDON_SUCCESS,
  UPDATE_ADDON_ERROR,
  ASSIGN_SUBSCRIPTION_CLOUD_AGREEMENT,
  ASSIGN_SUBSCRIPTION_BILLING_INFO,
  SET_FETCH_BILLING_INFO,
  SET_FETCH_CLOUD_AGREEMENT,
} from './../../constants/actionConstants';

const initialState = {
  isFetching: false,
  error: '',
}

function subscription(state, action) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION:
      if(state.id === action.id) {
        return {
          ...state,
          isUpdating: true,
        }
      }
      return state;
    
    case UPDATE_SUBSCRIPTION_SUCCESS:
      if(state.id === action.id) {
        return {
          ...state,
          isUpdating: false,
          quantity: action.quantity,
        }
      }
      return state;

    case UPDATE_SUBSCRIPTION_ERROR:
      if(state.id === action.id) {
        return {
          ...state,
          isUpdating: false,
        }
      }
      return state;

    case UPDATE_ADDON:
      if(state.id === action.subId) {
        return {
          ...state,
          listOfAddons: state.listOfAddons.map(addon => {
            if(addon.id === action.addonId) {
              return {
                ...addon,
                isUpdating: true,
              }
            }
            return addon;
          }),
        }
      }
      return state;

    case UPDATE_ADDON_SUCCESS:
      if(state.id === action.subId) {
        return {
          ...state,
          listOfAddons: state.listOfAddons.map(addon => {
            if(addon.id === action.addonId) {
              return {
                ...addon,
                isUpdating: false,
                quantity: action.quantity
              }
            }
            return addon;
          }),
        }
      }
      return state;

    case UPDATE_ADDON_ERROR:
      if(state.id === action.subId) {
        return {
          ...state,
          listOfAddons: state.listOfAddons.map(addon => {
            if(addon.id === action.addonId) {
              return {
                ...addon,
                isUpdating: false,
              }
            }
            return addon;
          }),
        }
      }
      return state;
    case SET_FETCH_CLOUD_AGREEMENT:
      if(state.cloudAccountId === action.id) {
        return {
          ...state,
          fetchingCloudAgreement: true,
        }
      }
      return state;
    case SET_FETCH_BILLING_INFO:
      if(state.cloudAccountId === action.id) {
        return {
          ...state,
          fetchingBillingInfo: true,
        }
      }
        return state;
    case ASSIGN_SUBSCRIPTION_CLOUD_AGREEMENT:
      if(state.cloudAccountId === action.id) {
        return {
          ...state,
          fetchingCloudAgreement: false,
          cloudAgreement: action.cloudAgreement
        }
      }
      return state;
    case ASSIGN_SUBSCRIPTION_BILLING_INFO:
      if(state.cloudAccountId === action.id) {
        return {
          ...state,
          fetchingBillingInfo: false,
          billingInfo: action.billingInfo
        }
      }
      return state;
    default:
      return state;
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_SUBSCRIPTIONS_SUCCESS:    
      return {
        ...state,
        error: '',
        isFetching: false,
        items: action.subscriptions.map(sub => ({
          ...sub,
          isUpdating: false,
          cloudAgreement: {},
          billingInfo: {},
          fetchingBillingInfo: false,
          fetchingCloudAgreement: false,
          listOfAddons: sub.addons ? sub.addons.map(addon => ({
            ...addon,
            isUpdating: false,
          })) : [],
        })),
      }
    case FETCH_SUBSCRIPTIONS_ERROR:
      return {
        ...initialState,
        isFetching: false,
        error: 'Unable to get subscriptions.'
      }
    case ASSIGN_SUBSCRIPTION_CLOUD_AGREEMENT:
    case ASSIGN_SUBSCRIPTION_BILLING_INFO:
    case SET_FETCH_BILLING_INFO:
    case SET_FETCH_CLOUD_AGREEMENT:
    case UPDATE_SUBSCRIPTION:
    case UPDATE_SUBSCRIPTION_SUCCESS:
    case UPDATE_SUBSCRIPTION_ERROR:
    case UPDATE_ADDON:
    case UPDATE_ADDON_SUCCESS:
    case UPDATE_ADDON_ERROR:
      return {
        ...state,
        items: state.items.map(sub => subscription(sub, action)),
      }
    default:
      return state;
  }
}
