import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTracking } from 'react-appinsights';
import { withTranslations } from './../../hocs/translations';
import withWidth from '@material-ui/core/withWidth';
import { Reports } from './../../components';

import { getReport } from './../../helpers/api';

class ReportsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      loadingBusinessDashboard: false,
      loadingCostExplorer: false,
      businessDashboardSettings: {},
      costExplorerSettings: {},
      showMobileInfo: props.width === 'xs',
    };
  }
  static propTypes = {
    translations: PropTypes.object.isRequired,
  }
  static defaultProps = {
  }
  handleChangeTab = (e, tabValue) => {
    this.setState({ tabValue });
  }

  closeMobileInfo = () => {
    this.setState({
      showMobileInfo: false,
    });
  }

  componentDidMount() {
    const { translations } = this.props;
    document.title = `${translations.title} - ${translations.reports}`;
    
    this.setState({
      loadingBusinessDashboard: true,
      loadingCostExplorer: true,
    });
    getReport('businessdashboard')
      .then((data) => {
        this.setState({
          loadingBusinessDashboard: false,
          businessDashboardSettings: data ? data : null,
        })
      })
      .catch(() => {
        this.setState({
          loadingBusinessDashboard: false,
        });
      });
    getReport('costexplorer')
      .then((data) => {
        this.setState({
          loadingCostExplorer: false,
          costExplorerSettings: data ? data : null,
        })
      })
      .catch(() => {
        this.setState({
          loadingCostExplorer: false,
        });
      });
  }

  render() {
    const {
      tabValue,
      loadingBusinessDashboard,
      loadingCostExplorer,
      businessDashboardSettings,
      costExplorerSettings,
      showMobileInfo,
    } = this.state;

    return (
      <Reports
        tabValue={tabValue}
        loadingBusinessDashboard={loadingBusinessDashboard}
        loadingCostExplorer={loadingCostExplorer}
        businessDashboardSettings={businessDashboardSettings}
        costExplorerSettings={costExplorerSettings}
        showMobileInfo={showMobileInfo}
        closeMobileInfo={this.closeMobileInfo}
        handleChangeTab={this.handleChangeTab}
      />
    )
  }
}

export default compose(
  connect(
    () => ({}),
  ),
  withWidth(),
  withTracking,
  withTranslations,
)(ReportsContainer);
