import React, { Component } from 'react';
import { compose } from 'redux';
import { withTracking } from 'react-appinsights';
import { withTranslations } from './../../hocs/translations';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Home } from './../../components';

class HomeContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    text: PropTypes.string,
    contact: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    translations: PropTypes.object.isRequired,
  }
  static defaultProps = {
    text: '',
    contact: {
      email: '',
      phone: '',
    },
  }
  componentDidMount() {
    document.title = this.props.translations.title
  }
  render() {
    const { text, contact, isFetching, error } = this.props;
    return (
      <Home text={text} contact={contact} isFetching={isFetching} error={error} />
    )
  }
}

export default compose(
  connect(
    ({ partnerInfo }) => partnerInfo,
  ),
  withTracking,
  withTranslations,
)(HomeContainer);
