import {
    get,
    put,
    post,
    delete_,
} from './apiService';

export function fetchPartnerInfo() {
    return get('/partnerInfo')
        .then(res => res.data);
}

export function fetchSubscriptions() {
    return get('/subscriptions')
        .then(res => res.data);
}

export function updateSubscription(subscriptionId, quantity) {
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         // reject();
    //         resolve();
    //     }, 5000)
    // })
    return put(`/subscriptions/${subscriptionId}/update-quantity`, quantity);
}

export function fetchCloudAgreement(subscriptionId) {
    return get(`/subscriptions/${subscriptionId}/customer-agreement`)
        .then(res => res.data);
}

export function fetchBillingInfo(subscriptionId) {
    return get(`/subscriptions/${subscriptionId}/usage-and-billing-information`)
        .then(res => res.data);
}

export function fetchUsers() {
    return get('/users')
        .then(res => res.data);
}

export function fetchSingleUser(id) {
    return get(`/users/${id}`)
        .then(res => res.data);
}

export function submitAccountInfo(id, user) {
    return put(`/users/${id}/update-information`, user);
}

export function submitNewPassword(id, user) {
    return post(`/users/${id}/update-password`, user);
}

export function inviteUser(emails) {
    return post('/users/invite', JSON.stringify(emails));
}

export function sendResetPasswordEmail(id) {
    return get(`/users/${id}/send-reset-password-email`);
}

export function deleteUser(id) {
    return delete_(`/users/${id}/delete`);
}

export function resetUserMFA(id) {
    return post(`/users/${id}/reset-mfa`, {});
}

export function getTranslations(lang) {
    return get(`/translations/${lang}`)
        .then(res => res.data)
}

export function getReport(reportName) {
    return get(`/powerbireports/${reportName}/`)
        .then(res => res.data)
}

export function fetchSupportedLanguages() {
    return get(`/translations/supported-languages`)
        .then(res => res.data)
}

