import { fetchPartnerInfo } from './../../helpers/api';
import {
  FETCH_PARTNER_INFO,
  FETCH_PARTNER_INFO_SUCCESS,
  FETCH_PARTNER_INFO_ERROR
} from './../../constants/actionConstants'

function beginFetchPartnerInfo() {
  return {
    type: FETCH_PARTNER_INFO,
  }
}

function fetchPartnerInfoSuccess(partnerInfo) {
  return {
    type: FETCH_PARTNER_INFO_SUCCESS,
    partnerInfo,
  }
}

function fetchPartnerInfoError(error) {
  return {
    type: FETCH_PARTNER_INFO_ERROR,
    error,
  }
}

export function handleFetchPartnerInfo() {
  return (dispatch) => {
    dispatch(beginFetchPartnerInfo());
    fetchPartnerInfo()
      .then(partnerInfo => dispatch(fetchPartnerInfoSuccess(partnerInfo)))
      .catch(error => dispatch(fetchPartnerInfoError(error)));
  }
}
