import {
  FETCH_TRANSLATIONS,
  FETCH_TRANSLATIONS_SUCCESS,
  FETCH_TRANSLATIONS_ERROR,
  FETCH_SUPPORTED_LANGUAGES_ERROR,
  FETCH_SUPPORTED_LANGUAGES_SUCCESS,
} from './../../constants/actionConstants';
import initialTranslations from './initialTranslations';

const initialState = {
  isFetching: false,
  error: '',
  currentLanguage: '',
  translations: initialTranslations,
  supportedLanguages: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSLATIONS:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        translations: {
          ...state.translations, 
          ...action.translations
        },
        currentLanguage: action.currentLanguage,
      }
    case FETCH_TRANSLATIONS_ERROR:
      return {
        ...initialState,
        isFetching: false,
        error: "Unable to fetch translations."
      }
    case FETCH_SUPPORTED_LANGUAGES_SUCCESS:
      return {
        ...state,
        supportedLanguages: action.supportedLanguages,
      }
    case FETCH_SUPPORTED_LANGUAGES_ERROR:
      return {
        ...state,
        supportedLanguages: [],
      }
    default:
      return state;
  }
}
