import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import { withTranslations } from './../../hocs/translations';
import { CustomButton, Can } from './../../components';
import {
  tableStyles,
  wrapper,
  innerWrapper,
} from './Users.module.less';
function Users(props) {
  const {
    isFetching,
    error,
    users,
    currentUserId,
    role,
    openModal,
    translations,
  } = props;
  if(error) return <p>{error}</p>
  return (
    <div style={{position: 'relative'}}>
      <h1>{translations.users}</h1>
      <Can
        role={role}
        perform="users:invite"
        yes={() => (
          <CustomButton
            onClick={() => openModal('inviteUserModal')}
            style={{position: 'absolute', right: 0, top: 0,}}
          >
            {translations.inviteUser}
          </CustomButton>
        )}
      />
      {
        isFetching
        ? <CircularProgress />
        : (
          <div className={wrapper}>
            <div className={innerWrapper}>
              <Table className={tableStyles}>
                <TableHead>
                  <TableRow>
                    <TableCell>{translations.user}</TableCell>
                    <TableCell>{translations.firstName}</TableCell>
                    <TableCell>{translations.lastName}</TableCell>
                    <TableCell>{translations.userRoleLabel}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map(user => (
                    <TableRow key={user.id}>
                      <TableCell>{user.email || '-'}</TableCell>
                      <TableCell>{user.firstName || '-'}</TableCell>
                      <TableCell>{user.lastName || '-'}</TableCell>
                      <TableCell>{user.role === 2 ? translations.adminRole : translations.userRole}</TableCell>
                      <TableCell align="right">
                        <Can
                          role={role}
                          perform="users:edit"
                          data={{
                            userId: currentUserId,
                            editUserId: user.id
                          }}
                          yes={() => (
                            <Link to={`users/${user.id}`}><IconButton><Create style={{width: 20, height: 20}} /></IconButton></Link>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )
      }
    </div>
  );
}

Users.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  currentUserId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
}

export default withTranslations(Users);