import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import partnerInfo from './partnerInfo/partnerinfoReducer';
import subscriptions from './subscriptions/subscriptionsReducer';
import alerts from './alerts/alertsReducer';
import users from './users/usersReducer';
import localization from './localization/localizationReducer';
import config from './config/configReducer';

const middleware = [
  thunk,
];

  // In development, use the browser's Redux dev tools extension if installed
const enhancers = [];
const isDevelopment = process.env.NODE_ENV === 'development';
if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
  enhancers.push(window.devToolsExtension());
}

const rootReducer = combineReducers({
  partnerInfo,
  subscriptions,
  alerts,
  users,
  localization,
  config,
});

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middleware), ...enhancers)
);

export default store; 
