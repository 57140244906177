import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import UpdateIcon from './../../assets/icons/update.svg';

import { CustomButton, CustomInput } from './../../components';
import { withStyles } from '@material-ui/core/styles';
import { withTranslations } from './../../hocs/translations';

import { formatDate } from './../../helpers/utils';
import { formatRenewalDate } from './../../helpers/utils';

import {
    status,
    active,
    column,
    columnNce,
    quantityColumnSummary,
} from './Subscriptions.module.less';

import {
    inputOverride,
    loadingButtonOverride,
    detailContainer,
    tooltip,
} from './SubscriptionsMaterialUiOverrides';

const styles = {
    inputOverride,
    loadingButtonOverride,
    detailContainer,
    tooltip,
}

const SubscriptionSummary = (props) => {
    const {
        isAddon,
        subscription,
        quantity,
        enableUpdate,
        handleUpdateQuantity,
        submitUpdateQuantity,
        detailClass,
        translations,
        classes,
    } = props;
    return (
        <Grid container alignItems="center" className={detailClass}>
            <Grid item xs={3}>
                <span className={column}>{subscription.productName || '-'}</span>
            </Grid>
            <Grid item xs={3}>
                <span className={column}>{subscription.friendlyName || '-'}</span>
                {subscription.isModernNCE ?
                    <span className={columnNce}> {translations.nceSubscription} </span> : null
                }
            </Grid>
            <Grid item xs={3}>
                {
                    isAddon
                        ? null
                        : (
                            <Grid container>
                                <Grid item xs={3} key={subscription.id + 'grid1'}>
                                    <span className={column}>{subscription.term || '-'}</span>
                                </Grid>
                                <Grid item xs={3} key={subscription.id + 'grid2'}>
                                    <span className={column}>{subscription.billingCycle || '-'}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <span className={column}>{subscription.createdDate ? formatDate(subscription.createdDate) : '-'}</span>
                                </Grid>
                                <Grid item xs={3}>
                                    <span className={column}>{subscription.renewalDate ? formatRenewalDate(subscription.renewalDate) : '-'}</span>
                                </Grid>
                            </Grid>
                        )
                }
            </Grid>
            <Grid item xs={3}>
                <div className={quantityColumnSummary}>
                    {
                        subscription.provisionType === 'Seat' || isAddon
                            ? (
                                <div>
                                    <CustomInput
                                        className={classes.inputOverride}
                                        id="quantity"
                                        value={quantity}
                                        InputProps={{
                                            inputProps: {
                                                min: 1
                                            }
                                        }}
                                        disabled={subscription.state !== 'Active' || subscription.isUpdating}
                                        onChange={(e) => handleUpdateQuantity(e.currentTarget.value, subscription.id)}
                                        type="number"
                                        onClick={(e) => e.stopPropagation()}
                                        submitFunction={submitUpdateQuantity}
                                    />
                                    <CustomButton
                                        type="primary"
                                        disabled={!enableUpdate || subscription.isUpdating}
                                        title={translations.update}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            submitUpdateQuantity()
                                        }}
                                    >
                                        {
                                            subscription.isUpdating
                                                ? <CircularProgress className={classes.loadingButtonOverride} />
                                                : <UpdateIcon style={{ width: 20, height: 20 }} />
                                        }
                                    </CustomButton>
                                </div>
                            )
                            : <span>-</span>
                    }
                    <div className={status}>
                        {
                         [ <CheckCircle style={{ width: 20, height: 20 }} key="stateIcon" className={active} />, <span key="stateText">{translations.active}</span>]
                        }
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

SubscriptionSummary.propTypes = {
    subscription: PropTypes.object.isRequired,
    isAddon: PropTypes.bool,
    handleUpdateQuantity: PropTypes.func.isRequired,
    submitUpdateQuantity: PropTypes.func.isRequired,
    quantity: PropTypes.string.isRequired,
    enableUpdate: PropTypes.bool.isRequired,
    detailClass: PropTypes.string,
    translations: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}

SubscriptionSummary.defaultProps = {
    isAddon: false,
    detailClass: '',
}

export default compose(
    withStyles(styles),
    withTranslations,
)(SubscriptionSummary);
