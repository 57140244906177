import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTracking } from 'react-appinsights';

import { withTranslations } from './../../hocs/translations';
import { fetchSingleUser, sendResetPasswordEmail } from './../../helpers/api';

import * as usersActionCreators from './../../store/users/usersActions';
import * as alertsActionCreators from './../../store/alerts/alertsActions';
import * as localizationActionCreators from './../../store/localization/localizationActions';

import CircularProgress from '@material-ui/core/CircularProgress'
import {
  EditUser,
  CustomModal,
  CustomButton,
} from './../../components';
import { logout } from '../../helpers/auth';

class EditUserContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      isFetching: false,
      loadingSaveAccount: false,
      loadingSavePassword: false,
      user: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        cultureCode: '',
        role: 1,
      },
      modalOpen: '',
      deleteUserLoading: false,
      resetMFALoading: false,
    } 
  }

  handleInputChange = (input, value) => {
    this.setState((state) => ({
      user: {
        ...state.user,
        [input]: value,
      },
    }))
  }

  submitAccountTab = () => {
    const {
      handleSubmitAccountInfo,
      match,
      history,
      addAlert,
      translations,
      consumerId,
      currentCultureCode,
      handleFetchTranslations,
    } = this.props;
    const { user } = this.state;
    const userId = match.params.id;
    const userInfo = {
      firstName: user.firstName,
      lastName: user.lastName,
      cultureCode: user.cultureCode,
      consumerId: consumerId,
      email: user.email,
      id: userId,
      role: user.role,
    }

    this.setState({ loadingSaveAccount: true })
    handleSubmitAccountInfo(userId, userInfo)
      .then(() => {
        if(userInfo.cultureCode.toLowerCase() !== currentCultureCode.toLowerCase()) {
          return handleFetchTranslations(userInfo.cultureCode).then(() => {
            addAlert('success', translations.editUserAccountSuccessAlert);
            history.push('/users');
          });
        } 
        addAlert('success', translations.editUserAccountSuccessAlert);
        history.push('/users');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message || translations.editUserAccountErrorAlert;
        addAlert('error', errorMessage);
        this.setState({ loadingSaveAccount: false })
      })
  }

  submitPasswordTab = () => {
    const {
      match,
      history,
      addAlert,
      translations,
    } = this.props;
    this.setState({ loadingSavePassword: true });
    const userId = match.params.id;

    sendResetPasswordEmail(userId)
      .then(() => {
        addAlert('success', translations.sendPasswordResetEmailSuccessAlert);
        history.push('/users');
      })
     .catch((error) => {
        const errorData = error.response.data;       
        addAlert('error', errorData || translations.sendPasswordResetEmailErrorAlert);
        this.setState({ loadingSavePassword: false });       
     });
  }

  openModal = (modal) => {
    this.setState({
      modalOpen: modal,
    })
  }

  deleteUser = () => {
    const {
      handleDeleteUser,
      addAlert,
      translations,
      currentUserId,
      history,
    } = this.props;
    const userId = this.state.user.id;
    this.setState({
      deleteUserLoading: true,
    })
    handleDeleteUser(userId)
      .then(() => {
        this.setState({
          modalOpen: '',
          userToDelete: {},
          deleteUserLoading: false,
        });
        addAlert('success', translations.deleteUserSuccessAlert);
        if(userId === currentUserId) {
          logout();
        } else {
          history.push('/users');
        }
      })
      .catch((error) => {
        this.setState({
          modalOpen: '',
          userToDelete: {},
          deleteUserLoading: false,
        });
        const errorMessage = error.response.data.message || translations.deleteUserErrorAlert  
        addAlert('error', errorMessage);
      })
    }

    resetMFA = () => {
        const {
            handleResetMFA,
            addAlert,
            translations
        } = this.props

        const userId = this.state.user.id;

        this.setState({
            resetMFALoading: true
        });

        handleResetMFA(userId)
            .then(() => {
                this.setState({
                    modalOpen: '',
                    resetMFALoading: false
                });
                addAlert('success', translations.resetMFASucess);
            })
            .catch((error) => {
                this.setState({
                    modalOpen: '',
                    resetMFALoading: false
                });

                const errorMessage = error.response.data.message;
                addAlert('error', errorMessage);
            })
    }

  onCloseModal = () => {
    this.setState({
      modalOpen: '',
    })
  }

  handleChangeTab = (e, tabValue) => {
    this.setState({ tabValue });
  }

  handleCancelEdit = () => {
    const { history } = this.props;
    history.push('/users');
  }

  componentDidMount() {
    const {
      match,
      history,
      addAlert,
      translations,
    } = this.props;
    document.title = `${translations.title} - ${translations.editUser}`;
    this.setState({ isFetching: true })
    fetchSingleUser(match.params.id)
      .then((user) => {
        this.setState({
          isFetching: false,
          user: {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            cultureCode: user.cultureCode.toLowerCase(),           
            role: user.role,
          }
        })
      })
      .catch(() => {
        addAlert('error', translations.noUserAlert);
        history.push('/users')
      })
  }

  render() {
    const {
      translations,
      supportedLanguages,
      role,
      currentUserId,
    } = this.props;
    const {
      tabValue,
      user,
      isFetching,
      loadingSaveAccount,
      loadingSavePassword,
      modalOpen,
      deleteUserLoading,
      resetMFALoading,
    } = this.state;
    return (
      <>
        <EditUser
          isFetching={isFetching}
          tabValue={tabValue}
          handleChangeTab={this.handleChangeTab}
          handleInputChange={this.handleInputChange}
          user={user}
          loadingSaveAccount={loadingSaveAccount}
          loadingSavePassword={loadingSavePassword}
          submitAccountTab={this.submitAccountTab}
          submitPasswordTab={this.submitPasswordTab}
          handleCancelEdit={this.handleCancelEdit}
          supportedLanguages={supportedLanguages}
          role={role}
          currentUserId={currentUserId}
          openModal={this.openModal}
        />
        <CustomModal
          key="deleteUserModal"
          open={modalOpen === 'deleteUserModal'}
          title={translations.deleteAccountModalTitle}
          onClose={this.onCloseModal}
          actionButtons={[
            <CustomButton key="cancel" type="secondary" onClick={this.onCloseModal}>{translations.cancel}</CustomButton>,
            <CustomButton key="delete" type="warning" onClick={this.deleteUser} disabled={deleteUserLoading}>
              {
                deleteUserLoading
                ? <CircularProgress style={{width: 20, height: 20, color: 'white'}} />
                : translations.deleteAccountModalButton
              }
              
            </CustomButton>
          ]}
        >
        <p>{translations.deleteAccountModalText} <strong>{`"${user.email}"` || '-'}</strong>.</p>
        </CustomModal>
        <CustomModal
            key="resetMFAModal"
            open={modalOpen === 'resetMFAModal'}
            title={translations.resetMFAModalButtonText}
            onClose={this.onCloseModal}
            actionButtons={[
                <CustomButton key="cancel" type="secondary" onClick={this.onCloseModal}>{translations.cancel}</CustomButton>,
                <CustomButton key="reset" type="warning" onClick={this.resetMFA}>
                    {   
                        resetMFALoading
                        ? <CircularProgress style={{ width: 20, height: 20, color: 'white' }} />
                        : translations.resetMFAModalButtonText
                    }
                </CustomButton>
            ]}
        >
                <p>{translations.resetMFAModalText} <strong>{`"${user.email}"` || '-'}</strong>?</p>
        </CustomModal>
      </>
    );
  }
}

const mapStateToProps = ({ users, localization }) => ({
  consumerId: users.userCredentials.consumer_id,
  supportedLanguages: localization.supportedLanguages,
  currentCultureCode: localization.currentLanguage,
  role: users.userCredentials.role,
  currentUserId: users.currentUserId,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    ...usersActionCreators,
    ...alertsActionCreators,
    ...localizationActionCreators,
  }, dispatch)
);

export default compose(
  withRouter,
  withTranslations,
  withTracking,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EditUserContainer);