import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import * as partnerInfoActionCreators from './../../store/partnerInfo/partnerInfoActions';
import * as localizationActionCreators from './../../store/localization/localizationActions';
import * as usersActionCreators from './../../store/users/usersActions';

import { queryStringToObject, parseJwt } from './../../helpers/utils';

function CallbackContainer(props) {
  useEffect(() => {
    handleCallback();
  }, []);

  const handleCallback = () => {
    const {
      handleFetchPartnerInfo,
      handleFetchTranslations,
      handleFetchSupportedLanguages,
      setCurrentUserId,
      location,
      history,
      assignUserCredentials,
    } = props;

    const hash = location.hash;
    const queryString = hash.substring(1, hash.length);
    const obj = queryStringToObject(queryString);

    if(!obj.id_token || !obj.access_token) {
      history.push('/');
      return;
      }
   
    const id_token = parseJwt(obj.id_token);
    const { nonce, sub, locale } = id_token;
    const role = id_token.role === 'TenantAdmin' ? 'tenantAdmin' : id_token.role === 'ConsumerAdmin' ? 'consumerAdmin' : 'user';
    const consumerId = id_token.consumerId || sessionStorage.getItem('requestedConsumerId');
    const path = localStorage.getItem(nonce);

    assignUserCredentials({
      access_token: obj.access_token,
      id_token: obj.id_token,
      consumer_id: consumerId,
      role,
    });

    localStorage.removeItem(nonce);
    
    if(!consumerId) {
      setCurrentUserId(sub);
      history.push('/');
    } else {
      setCurrentUserId(sub);
      Promise.all([handleFetchTranslations(locale), handleFetchPartnerInfo(), handleFetchSupportedLanguages()])
        .then(() => {
          history.push(path || '/');
        });
    }
    
  }

  return <></>
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    ...partnerInfoActionCreators,
    ...localizationActionCreators,
    ...usersActionCreators,
  }, dispatch)  
)
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(CallbackContainer);