const rules = {
    user: {
        dynamic: {
            'users:edit': ({ userId, editUserId }) => {
                if (!userId || !editUserId) return false;
                return userId === editUserId;
            },
            'users:delete': ({ userId, deleteUserId }) => {
                if (!userId || !deleteUserId) return false;
                return userId === deleteUserId;
            },
        },
        static: [
            'users:signOut'
        ],
    },

    consumerAdmin: {
        static: [
            'users:edit',
            'users:invite',
            'users:delete',
            'users:editRole',
            'users:signOut',
        ],
        dynamic: {
            'users:resetMFA': ({ userId, editUserId }) => {
                if (!userId || !editUserId) return false;
                return userId !== editUserId;
            },
        }
    },

    tenantAdmin: {
        static: [
            'users:invite',
        ],
        dynamic: {
            'users:edit': ({ userId, editUserId }) => {
                if (!userId || !editUserId) return false;
                return userId !== editUserId;
            },
            'users:delete': ({ userId, deleteUserId }) => {
                if (!userId || !deleteUserId) return false;
                return userId !== deleteUserId;
            },
            'users:editRole': ({ userId, editUserId }) => {
                if (!userId || !editUserId) return false;
                return userId !== editUserId;
            },
            'users:resetMFA': ({ userId, editUserId }) => {
                if (!userId || !editUserId) return false;
                return userId !== editUserId;
            },
        }
    }
};

export default rules;