import {
  ADD_ALERT,
  REMOVE_ALERT,
} from './../../constants/actionConstants';

export function addAlert(alertType, message) {
  const id = `${Date.now()}`;
  return {
    type: ADD_ALERT,
    alertType,
    message,
    id,
  }
}

export function removeAlert(id) {
  return {
    type: REMOVE_ALERT,
    id,
  }
}