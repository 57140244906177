import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import {
  modalWrapper,
  actionsWrapper,
} from './CustomModal.module.less'

const CustomModal = (props) => {
  const {
    open,
    onClose,
    children,
    title,
    actionButtons,
  } = props;
  return (
    <Modal
      aria-labelledby={title}
      open={open}
      onClose={onClose}
    >
      <div className={modalWrapper}>
        <h3>{title}</h3>
        {children}
        <div className={actionsWrapper}>
          {actionButtons}
        </div>
      </div>
    </Modal>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  actionButtons: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ])
}

export default CustomModal;