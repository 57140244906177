import React from 'react';
import PropTypes from 'prop-types';
import { withTranslations } from '../../hocs/translations';

const Logout = ({ translations }) => {
  const t = JSON.parse(sessionStorage.getItem('translations'));
  const signedOutTitle = t ? t.signedOutTitle : translations.signedOutTitle;
  const signedOutText = t ? t.signedOutText : translations.signedOutText;
  sessionStorage.removeItem('translations');
  return (
    <div>
      <h1>{signedOutTitle}</h1>
      <p>{signedOutText}</p>
    </div>
  );
};

Logout.propTypes = {
  translations: PropTypes.object.isRequired,
}

export default withTranslations(Logout);