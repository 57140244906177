import {
  ADD_ALERT,
  REMOVE_ALERT,
} from './../../constants/actionConstants';


export default function (state = [], action) {
  switch (action.type) {
    case ADD_ALERT:
      const newAlert = {
        type: action.alertType,
        message : action.message,
        id: action.id,
      }
      return state.concat([newAlert])
    case REMOVE_ALERT:
      return state.filter(alert => {
        return alert.id !== action.id
      })
    default:
      return state;
  }
}
