import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslations } from './../../hocs/translations';
import { withTracking } from 'react-appinsights';

const NotFound = ({ translations }) => {
  return (
    <div>
      <h1>{translations.notFoundTitle}</h1>
      <p>{translations.notFoundText}</p>
    </div>
  );
};

NotFound.propTypes = {
  translations: PropTypes.object.isRequired,
}

export default compose(
  withTranslations,
  withTracking,
)(NotFound);
