exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".tableStyles___GIauw tr th,\n.tableStyles___GIauw tr td {\n  font-family: \"OpenSans\";\n  font-size: 12px;\n  color: #000000;\n}\n.tableStyles___GIauw tr th svg,\n.tableStyles___GIauw tr td svg {\n  color: #a0a0a0;\n}\n.tableStyles___GIauw tr th {\n  font-weight: 500;\n  font-family: \"OpenSans-Bold\";\n  font-weight: 100;\n  color: #a0a0a0;\n}\n.wrapper___-7FeC {\n  background: white;\n  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);\n  border-radius: 4px;\n  overflow-x: auto;\n}\n.innerWrapper___2Sa1F {\n  min-width: 650px;\n}\n", "", {"version":3,"sources":["C:/agent3/vsts-agent-win-x64-2.153.2/_work/2276/s/src/Crayon.EndCustomerPortal/ClientApp/src/components/Users/Users.module.less"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;CAChB;AACD;;EAEE,eAAe;CAChB;AACD;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,iBAAiB;EACjB,eAAe;CAChB;AACD;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,mBAAmB;EACnB,iBAAiB;CAClB;AACD;EACE,iBAAiB;CAClB","file":"Users.module.less","sourcesContent":[".tableStyles tr th,\n.tableStyles tr td {\n  font-family: \"OpenSans\";\n  font-size: 12px;\n  color: #000000;\n}\n.tableStyles tr th svg,\n.tableStyles tr td svg {\n  color: #a0a0a0;\n}\n.tableStyles tr th {\n  font-weight: 500;\n  font-family: \"OpenSans-Bold\";\n  font-weight: 100;\n  color: #a0a0a0;\n}\n.wrapper {\n  background: white;\n  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);\n  border-radius: 4px;\n  overflow-x: auto;\n}\n.innerWrapper {\n  min-width: 650px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"tableStyles": "tableStyles___GIauw",
	"wrapper": "wrapper___-7FeC",
	"innerWrapper": "innerWrapper___2Sa1F"
};