import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const CustomInput = (props) => {
  const {
    value,
    submitFunction,
    type,
    disabled,
    children,
    ...others
  } = props;
  return (
    <TextField
      value={value}
      onKeyPress={(e) => {
        if(e.key === 'Enter' && !disabled && !!submitFunction) {
          submitFunction();
          e.target.blur();
        }
      }}
      type={type}
      margin="normal"
      variant="outlined"
      disabled={disabled}
      {...others}
    >
      {children}
    </TextField>
  );
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  type: PropTypes.string,
  submitFunction: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
}
CustomInput.defaultProps = {
  type: 'text',
  children: null,
  submitFunction: null,
  disabled: false,
}

export default CustomInput;
