import React from 'react';
import PropTypes from 'prop-types';
import { withTranslations } from './../../hocs/translations';

const Unauthorized = ({ translations }) => {
  return (
    <div>
      <h1>{translations.unauthorizedTitle}</h1>
      <p>{translations.unauthorizedText}</p>
    </div>
  );
};

Unauthorized.propTypes = {
  translations: PropTypes.object.isRequired,
}

export default withTranslations(Unauthorized);