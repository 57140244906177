import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import { withTranslations } from './../../hocs/translations';
import EditAccountTab from './EditAccountTab';
import EditPasswordTab from './EditPasswordTab';
import ManageMFATab from './ManageMFATab';
import { CustomButton, Can } from '../../components';

const styles =  {
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  tabsRoot: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
  },
  tabsIndicator: {
    backgroundColor: '#3071ad',
  },
  tabContentWrapper: {
    padding: 30,
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '& span': {
      fontSize: 13,
      fontWeight: 700,
      color: '#a0a0a0',
    },
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {
    '& span': {
      color: '#3071ad',
    }
  },
  actionButtons: {
    marginTop: 40,
    '& button': {
      marginRight: 10,
    }
  }
};

function EditUser(props) {
  const {
    isFetching,
    tabValue,
    handleChangeTab,
    user,
    handleInputChange,
    submitAccountTab,
    submitPasswordTab,
    handleCancelEdit,
    loadingSaveAccount,
    loadingSavePassword,
    supportedLanguages,
    role,
    currentUserId,
    translations,
    classes,
    openModal,
  } = props;
  if(isFetching) return <CircularProgress />
  return (
    <div style={{position: 'relative'}}>
      <h1>{translations.editUser}</h1>
      <Can
        role={role}
        perform="users:delete"
        data={{
          userId: currentUserId,
          deleteUserId: user.id,
        }}
        yes={() => (
          <CustomButton
            onClick={() => openModal('deleteUserModal')}
            type="warning"
            style={{position: 'absolute', right: 0, top: 0,}}
          >
            {translations.deleteAccountModalButton}
          </CustomButton>
        )}
      />
      <div className={classes.root}>
        <Tabs 
          value={tabValue}
          onChange={handleChangeTab}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={translations.accountTab}
          />
                  <Tab
                      classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                      label={translations.passwordTab}
                  />
            <Tab
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                label={translations.manageMFATab}
            />
        </Tabs>
        <div className={classes.tabContentWrapper}>
          {tabValue === 0 &&
            <EditAccountTab
              user={user}
              role={role}
              currentUserId={currentUserId}
              handleCancelEdit={handleCancelEdit}
              submitAccountTab={submitAccountTab}
              handleInputChange={handleInputChange}
              actionButtonStyles={classes.actionButtons}
              loadingSaveAccount={loadingSaveAccount}
              supportedLanguages={supportedLanguages}
            />
          }
          {(tabValue === 1) &&
            <EditPasswordTab
              handleCancelEdit={handleCancelEdit}
              submitPasswordTab={submitPasswordTab}
              actionButtonStyles={classes.actionButtons}
              loadingSavePassword={loadingSavePassword}
            />
          }
          {(tabValue === 2) &&
            <ManageMFATab
                user={user}
                role={role}
                currentUserId={currentUserId}
                handleCancelEdit={handleCancelEdit}
                handleResetMFA={() => openModal('resetMFAModal') }
                actionButtonStyles={classes.actionButtons}
            />
          }
        </div>
      </div>
    </div>
  );
}

EditUser.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  tabValue: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  submitAccountTab: PropTypes.func.isRequired,
  submitPasswordTab: PropTypes.func.isRequired,
  handleCancelEdit: PropTypes.func.isRequired,
  loadingSavePassword: PropTypes.bool.isRequired,
  loadingSaveAccount: PropTypes.bool.isRequired,
  supportedLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      culture: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    cultureCode: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  withTranslations,
)(EditUser);