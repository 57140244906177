import React from 'react';
import PropTypes from 'prop-types';
import { withTranslations } from './../../hocs/translations';
import { logout, goToSignIn, hasToken, endSessionAndCloseTab } from './../../helpers/auth';

import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Can } from './../../components';

function ProfileMenu({ toggleProfileMenu, profileMenuAnchor, userId, role, isAuthorized, translations }) {
  return (
    <div>
      <IconButton
        aria-owns={'menu-appbar'}
        aria-haspopup="true"
        color="inherit"
        onClick={(e) => toggleProfileMenu(e.currentTarget, true)}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={profileMenuAnchor}
        open={Boolean(profileMenuAnchor)}
        onClose={toggleProfileMenu}
      >
        {isAuthorized && (
            <Can
              role={role}
              perform="users:edit"
              data={{
                userId: userId,
                editUserId: userId,
              }}
              yes={() => (
                <MenuItem onClick={toggleProfileMenu} component={Link} to={`/users/${userId}`}>
                  {translations.myProfile}
                </MenuItem>
              )}
            />
        )}
        {
          hasToken()
            ? (
              <Can
                role={role}
                perform="users:signOut"
                yes={() => (
                  <MenuItem onClick={() => {
                    toggleProfileMenu();
                    logout();
                  }}>
                    {translations.signOut}
                  </MenuItem>
                )}
                no={() => (
                  <MenuItem onClick={() => {
                    endSessionAndCloseTab();
                  }}>
                    {translations.signOut}
                  </MenuItem>
                )}
             />
            ) : (
            <MenuItem onClick={() => {
              toggleProfileMenu();
              goToSignIn('/');
            }}>
              {translations.signIn}
            </MenuItem>
           )
        }
        
      </Menu>
    </div>
  );
}

ProfileMenu.propTypes = {
  toggleProfileMenu: PropTypes.func.isRequired,
  profileMenuAnchor: PropTypes.object,
  isAuthorized: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired,
  userId: PropTypes.string,
  role: PropTypes.string,
}

export default withTranslations(ProfileMenu);
