import {
  FETCH_CONFIG,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_ERROR,
} from './../../constants/actionConstants';

const initialState = {
  isFetching: true,
  error: '',
  config: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONFIG:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        config: action.config,
      }
    case FETCH_CONFIG_ERROR:
      return {
        ...state,
        isFetching: false,
        error: 'Could not fetch config',
      }
    default:
      return state;
  }
}
