import React from 'react';
import PropTypes from 'prop-types';

import { withTranslations } from './../../hocs/translations';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CustomButton,
  CustomInput,
  Can,
} from '../../components';

function EditAccountTab(props) {
  const {
    user,
    role,
    currentUserId,
    handleInputChange,
    submitAccountTab,
    handleCancelEdit,
    actionButtonStyles,
    loadingSaveAccount,
    supportedLanguages,
    translations,
  } = props;
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={4}>
            <CustomInput
              id="email"
              label={translations.emailAddress}
              disabled
              value={user.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={4}>
            <CustomInput
              id="first-name"
              label={translations.firstName}
              value={user.firstName}
              onChange={(e) => handleInputChange('firstName', e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={4}>
            <CustomInput
              id="last-name"
              label={translations.lastName}
              value={user.lastName}
              onChange={(e) => handleInputChange('lastName', e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} md={6} lg={4}>
            <CustomInput
              id="language"
              select
              label={translations.language}
              value={user.cultureCode || ''}
              onChange={(e) => handleInputChange('cultureCode', e.target.value)}
              fullWidth
            >
              {
                supportedLanguages.map((language) => (
                  <MenuItem key={language.culture} value={language.culture}>{language.name}</MenuItem>
                ))
              }
            </CustomInput>
          </Grid>
        </Grid>
        <Can
          role={role}
          perform="users:editRole"
          data={{
            userId: currentUserId,
            editUserId: user.id,
          }}
          yes={() => (
            <Grid item xs={12}>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  id="role"
                  select
                  label={translations.userRoleSelect}
                  value={user.role || 1}
                  onChange={(e) => handleInputChange('role', e.target.value)}
                  fullWidth
                >
                  <MenuItem value={1}>{translations.userRole}</MenuItem>
                  <MenuItem value={2}>{translations.adminRole}</MenuItem>
                </CustomInput>
              </Grid>
            </Grid>
          )}
        />
      </Grid>

      <div className={actionButtonStyles}>
        <CustomButton onClick={submitAccountTab} disabled={loadingSaveAccount} style={{minWidth: 110}}>
          {
            loadingSaveAccount
            ? <CircularProgress style={{width: 20, height: 20}} />
            : translations.saveChanges
          }
        </CustomButton>
        <CustomButton onClick={handleCancelEdit} disabled={loadingSaveAccount} type="secondary">{translations.cancel}</CustomButton>
      </div>
    </div>
  )
}

EditAccountTab.propTypes = {
  user: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  actionButtonStyles: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  submitAccountTab: PropTypes.func.isRequired,
  handleCancelEdit: PropTypes.func.isRequired,
  loadingSaveAccount: PropTypes.bool.isRequired,
  supportedLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      culture: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  translations: PropTypes.object.isRequired,
}
export default withTranslations(EditAccountTab);