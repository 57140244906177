import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslations } from './../../hocs/translations';

import * as alertActionCreators from './../../store/alerts/alertsActions';
import * as subscriptionsActionCreators from './../../store/subscriptions/subscriptionsActions';
import {
  Subscription,
  CustomButton,
  CustomModal
} from './../../components'

export class SubscriptionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      quantity: `${props.subscription.quantity}`,
      enableUpdate: false,
      errorModalOpen: false,
    }
  }
  static propTypes = {
    subscription: PropTypes.object.isRequired,
    handleUpdateSubscription: PropTypes.func.isRequired,
    handleUpdateAddon: PropTypes.func.isRequired,
    handleFetchCloudAgreement: PropTypes.func.isRequired,
    handleFetchBillingInfo: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
  }

  handleExpandPanelChange = () => {
    const {
      handleFetchCloudAgreement,
      handleFetchBillingInfo,
      subscription,
    } = this.props;
    this.setState((state) => ({
      expanded: !state.expanded,
    }), () => {
      if(this.state.expanded && !Object.keys(subscription.cloudAgreement).length && !subscription.fetchingCloudAgreement){
        handleFetchCloudAgreement(subscription.id, subscription.cloudAccountId);
      }
      if(this.state.expanded && !Object.keys(subscription.billingInfo).length && !subscription.fetchingBillingInfo){
        handleFetchBillingInfo(subscription.id, subscription.cloudAccountId);
      }
    });
  }

  handleUpdateQuantity = (quantity) => {
    const originalQuantity = this.props.subscription.quantity;
    this.setState({
      quantity: quantity,
      enableUpdate: originalQuantity !== Number(quantity),
    });
  }
  
  handleCloseModal = () => {
    this.setState({
      errorModalOpen: false,
    })
  }

  openErrorModal = () => {
    this.setState({
      errorModalOpen: true,
    });
  }

  submitUpdateQuantity = () => {
    const {
      handleUpdateSubscription,
      addAlert,
      subscription,
    } = this.props;
    const quantity = Number(this.state.quantity);

    if(quantity < 1) {
      this.setState({
        quantity: `${subscription.quantity}`,
        enableUpdate: false,
      })
      this.openErrorModal();
      return;
    }

      return handleUpdateSubscription(subscription.id, quantity)
          .then(() => {
              this.setState({
                  enableUpdate: false,
                  quantity: `${this.props.subscription.quantity}`,
              });
              addAlert('success', this.props.translations.subscriptionSuccessAlert);
          })
          .catch((error) => {
              if (error.response.data.errorCode === 'PARTNER_CENTER_EXCEPTION') {
                  addAlert('error', error.response.data.message.toString());
              }
              else if (error.response.data.errorCode === 'MaxTresholdError') {
                  addAlert('error', error.response.data.message.toString());
              } else {
                  addAlert('error', error.response.data.message.toString());
              }
              const originalQuantity = `${this.props.subscription.quantity}`;
              this.setState({
                  enableUpdate: false,
                  quantity: originalQuantity,
              });
          });
  }

  render() {
    const {
      subscription,
      translations,
    } = this.props;
    const {
      expanded,
      quantity,
      enableUpdate,
      errorModalOpen,
    } = this.state;
    
    return (
      <>
        <Subscription
          subscription={subscription}
          expanded={expanded}
          quantity={quantity}
          enableUpdate={enableUpdate}
          handleUpdateQuantity={this.handleUpdateQuantity}
          handleExpandPanelChange={this.handleExpandPanelChange}
          submitUpdateQuantity={this.submitUpdateQuantity}
          openErrorModal={this.openErrorModal}
        />
        <CustomModal
          open={errorModalOpen}
          onClose={this.handleCloseModal}
          title={translations.suspendModalTitle}
          actionButtons={
            <CustomButton onClick={this.handleCloseModal}>
              {translations.ok}
            </CustomButton>
          }
        >
          <p>{translations.suspendModalText}</p>
        </CustomModal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    ...alertActionCreators,
    ...subscriptionsActionCreators,
  }, dispatch)
);

export default compose(
  connect(
    () => ({}),
    mapDispatchToProps,
  ),
  withTranslations,
)(SubscriptionContainer);
