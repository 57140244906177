export const FETCH_PARTNER_INFO = 'FETCH_PARTNER_INFO';
export const FETCH_PARTNER_INFO_SUCCESS = 'FETCH_PARTNER_INFO_SUCCESS';
export const FETCH_PARTNER_INFO_ERROR = 'FETCH_PARTNER_INFO_ERROR';

export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'FETCH_SUBSCRIPTIONS_ERROR';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_ADDON = 'UPDATE_ADDON';
export const UPDATE_ADDON_ERROR = 'UPDATE_ADDON_ERROR';
export const UPDATE_ADDON_SUCCESS = 'UPDATE_ADDON_SUCCESS';
export const ASSIGN_SUBSCRIPTION_CLOUD_AGREEMENT = 'ASSIGN_SUBSCRIPTION_CLOUD_AGREEMENT';
export const ASSIGN_SUBSCRIPTION_BILLING_INFO = 'ASSIGN_SUBSCRIPTION_BILLING_INFO';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const RESET_USER_MFA = 'RESET_USER_MFA';
export const APPLY_NEW_USER_INFO = 'APPLY_NEW_USER_INFO';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';

export const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS';
export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_ERROR = 'FETCH_TRANSLATIONS_ERROR';

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCH_CONFIG_SUCCESS = 'FETH_CONFIG_SUCCESS';
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR';
export const ASSIGN_USER_CREDENTIALS = 'ASSIGN_USER_CREDENTIALS';
export const FETCH_SUPPORTED_LANGUAGES_SUCCESS = 'FETCH_SUPPORTED_LANGUAGES_SUCCESS';
export const FETCH_SUPPORTED_LANGUAGES_ERROR = 'FETCH_SUPPORTED_LANGUAGES_ERROR';

export const SET_FETCH_CLOUD_AGREEMENT = 'SET_FETCH_CLOUD_AGREEMENT';
export const SET_FETCH_BILLING_INFO = 'SET_FETCH_BILLING_INFO';