import {
  fetchUsers,
  deleteUser,
  submitAccountInfo,
  resetUserMFA,
} from './../../helpers/api';
import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  DELETE_USER,
  RESET_USER_MFA,
  APPLY_NEW_USER_INFO,
  SET_CURRENT_USER_ID,
  ASSIGN_USER_CREDENTIALS,
} from './../../constants/actionConstants'

function beginFetchUsersInfo() {
  return {
    type: FETCH_USERS,
  }
}

function fetchUsersSuccess(users) {
  return {
    type: FETCH_USERS_SUCCESS,
    users,
  }
}

function fetchUsersError(error) {
  return {
    type: FETCH_USERS_ERROR,
    error,
  }
}

function deleteUserSuccess(id) {
  return {
    type: DELETE_USER,
    id,
  }
}

function resetUserMFASuccess(id) {
    return {
        type: RESET_USER_MFA,
        id,
    };
}

function applyNewUserInfo(id, userInfo) {
  return {
    type: APPLY_NEW_USER_INFO,
    id,
    userInfo,
  }
}

export function assignUserCredentials(userCredentials) {
  return {
    type: ASSIGN_USER_CREDENTIALS,
    userCredentials,
  }
}

export function setCurrentUserId(id) {
  return {
    type: SET_CURRENT_USER_ID,
    id,
  }
} 

export function handleDeleteUser(id) {
  return (dispatch) => {
    return deleteUser(id)
      .then(() => dispatch(deleteUserSuccess(id)))
  }
}

export function handleResetMFA(id) {
    return (dispatch) => {
        return resetUserMFA(id)
            .then(() => dispatch(resetUserMFASuccess(id)))
    }
}

export function handleSubmitAccountInfo(id, user) {
  return (dispatch) => {
    return submitAccountInfo(id, user)
      .then(() => dispatch(applyNewUserInfo(id, user)))
  }
}

export function handleFetchUsers() {
  return (dispatch) => {
    dispatch(beginFetchUsersInfo());
    fetchUsers()
      .then(users => dispatch(fetchUsersSuccess(users)))
      .catch(error => dispatch(fetchUsersError(error)));
  }
}
