import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTracking } from 'react-appinsights';
import { bindActionCreators } from 'redux';

import { withTranslations } from './../../hocs/translations';
import * as UsersActionCreators from './../../store/users/usersActions';
import * as AlertActionCreators from './../../store/alerts/alertsActions';

import { inviteUser as inviteUserApi } from './../../helpers/api';
import { validateEmail } from './../../helpers/utils'

import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Users,
  CustomModal,
  CustomButton,
  CustomInput,
} from './../../components';

class UsersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: '',
      inviteEmailAddresses: '',
      inviteEmailError: false,
      userToDelete: {},
      deleteUserLoading: false,
      inviteUserLoading: false,
    }
  }

  static propTypes = {
    handleFetchUsers: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    users: PropTypes.array,
    currentUserId: PropTypes.string,
    translations: PropTypes.object.isRequired,
  }

  static defaultProps = {
    users: [],
    currentUserId: '',
  }

  componentDidMount() {
    const {
      handleFetchUsers,
      users,
      isFetching,
      translations,
    } = this.props;
    document.title = `${translations.title} - ${translations.users}`;
    if(!isFetching && !users.length) {
      handleFetchUsers();
    }
  }
  openModal = (modal, user) => {
    this.setState({
      modalOpen: modal,
      userToDelete: user || {},
    })
  }

  handleChangeInvitationEmail = (text) => {
    this.setState({
      inviteEmailAddresses: text,
      inviteEmailError: false,
    })
  };

  inviteUser = () => {
    const {
      addAlert,
      translations,
    } = this.props;
    const emailAddresses = this.state.inviteEmailAddresses; 
    const emailsIsValid = validateEmail(emailAddresses)
    if(!emailsIsValid) {
      return this.setState({
        inviteEmailError: true,
      })
    }
    this.setState({
      inviteUserLoading: true,
    });
    inviteUserApi(this.state.inviteEmailAddresses)
      .then(() => {
        this.setState({
          modalOpen: '',
          inviteUserLoading: false,
          inviteEmailAddresses: '',
        });
        addAlert('success', translations.emailInvitedSuccessAlert);
      })
      .catch((error) => {
        this.setState({
          modalOpen: '',
          inviteUserLoading: false,
          inviteEmailAddresses: '',
        });
        if(error.response.data === 'emailAlreadyExistsErrorAlert') {
          addAlert('error', translations.emailAlreadyExistsErrorAlert);
        } else {
          addAlert('error', translations.emailInvitedErrorAlert);
        }
      })
  }

  deleteUser = () => {
    const {
      handleDeleteUser,
      addAlert,
      translations,
    } = this.props;
    const userId = this.state.userToDelete.id;
    this.setState({
      deleteUserLoading: true,
    })
    handleDeleteUser(userId)
      .then(() => {
        this.setState({
          modalOpen: '',
          userToDelete: {},
          deleteUserLoading: false,
        });
        addAlert('success', translations.deleteUserSuccessAlert);
      })
      .catch(() => {
        this.setState({
          modalOpen: '',
          userToDelete: {},
          deleteUserLoading: false,
        });
        addAlert('error', translations.deleteUserErrorAlert);
      })
  }

  onCloseModal = () => {
    this.setState({
      modalOpen: '',
    })
  }

  render() {
    const {
      isFetching,
      error,
      users,
      currentUserId,
      role,
      translations,
    } = this.props;
    const {
      modalOpen,
      userToDelete,
      deleteUserLoading,
      inviteUserLoading,
      inviteEmailAddresses,
      inviteEmailError,
    } = this.state;
    return([
      <Users
        key="users"
        isFetching={isFetching}
        error={error}
        users={users}
        currentUserId={currentUserId}
        role={role}
        openModal={this.openModal}
      />,
      <CustomModal
        key="deleteUserModal"
        open={modalOpen === 'deleteUserModal'}
        title={translations.deleteAccountModalTitle}
        onClose={this.onCloseModal}
        actionButtons={[
          <CustomButton key="cancel" type="secondary" onClick={this.onCloseModal}>{translations.cancel}</CustomButton>,
          <CustomButton key="delete" type="warning" onClick={this.deleteUser} disabled={deleteUserLoading}>
            {
              deleteUserLoading
              ? <CircularProgress style={{width: 20, height: 20, color: 'white'}} />
              : translations.deleteAccountModalButton
            }
            
          </CustomButton>
        ]}
      >
        <p>{translations.deleteAccountModalText} <strong>{`"${userToDelete.email}"` || '-'}</strong>.</p>
      </CustomModal>,
      <CustomModal
        key="inviteUserModal"
        open={modalOpen === 'inviteUserModal'}
        title={translations.inviteUserModalTitle}
        onClose={this.onCloseModal}
        actionButtons={[
          <CustomButton key="cancel" type="secondary" onClick={this.onCloseModal}>{translations.cancel}</CustomButton>,
          <CustomButton key="invite" type="primary" onClick={this.inviteUser} disabled={inviteUserLoading || inviteEmailError || !inviteEmailAddresses}>
            {
              inviteUserLoading
              ? <CircularProgress style={{width: 20, height: 20}} />
              : translations.inviteUserModalButton
            }
            
          </CustomButton>
        ]}
      >
        <CustomInput
          id="email-address-input"
          label={translations.emailAddress}
          helperText={inviteEmailError ? translations.invalidEmail : ''}
          value={inviteEmailAddresses}
          onChange={(e) => this.handleChangeInvitationEmail(e.target.value)}
          submitFunction={this.inviteUser}
          disabled={inviteUserLoading}
          fullWidth
          error={inviteEmailError}
        />
        <span style={{
          paddingLeft: 12,
          fontSize: 12,
          color: '#a0a0a0',
        }}>{translations.emailAddressHelper}</span>
      </CustomModal>
    ])
  }
}

const mapStateToProps = ({ users }) => ({
  isFetching: users.isFetching,
  error: users.error,
  users: users.items,
  currentUserId: users.currentUserId,
  role: users.userCredentials.role,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
   ...UsersActionCreators,
   ...AlertActionCreators,
  }, dispatch)
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTranslations,
  withTracking,
)(UsersContainer);
