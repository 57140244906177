import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Header } from './../../components';
import { getImage } from '../../helpers/apiService';

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavigationOpen: false,
      profileMenuAnchor: null,
      logo: '',
    };
  }
  static propTypes = {
    logo: PropTypes.string,
    userId: PropTypes.string,
  }
  static defaultProps = {
    logo: '',
    userId: '',
  }

  componentWillReceiveProps(props) {
    if (!this.props.logo && props.logo) {
      getImage(props.logo).then(res => {
        this.setState({
          logo: res,
        });
      });
    }
  }

  toggleNavigation() {
    this.setState((state) => ({
      isNavigationOpen: !state.isNavigationOpen, 
    }));
  }

  toggleProfileMenu(el, doOpenMenu) {
    this.setState({
      profileMenuAnchor: doOpenMenu === true ? el : null,
    });
  }

  render() {
    const { logo, isNavigationOpen, profileMenuAnchor } = this.state;
    const { userId, isAuthorized, role } = this.props;
    return (
      <Header
        isNavigationOpen={isNavigationOpen}
        profileMenuAnchor={profileMenuAnchor}
        logo={logo}
        userId={userId}
        role={role}
        isAuthorized={isAuthorized}
        toggleNavigation={() => this.toggleNavigation()}
        toggleProfileMenu={(el, doOpenMenu) => this.toggleProfileMenu(el, doOpenMenu)}
      />
    )
  }
}

const mapStateToProps = ({ partnerInfo, users }) => ({
  logo: partnerInfo.logo,
  userId: users.currentUserId,
  role: users.userCredentials.role,
}); 

export default compose(
  withRouter,
  connect(
    mapStateToProps,
  ),
)(HeaderContainer);
