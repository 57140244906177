import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withTranslations } from './../../hocs/translations';

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Info from '@material-ui/icons/Info'
import Close from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import PowerbiEmbedded from 'react-powerbi'

import {
  container,
  innerContainer,
  paper,
  paperContent,
  closeBtn,
} from './Reports.module.less';

const styles =  {
  root: {
    flexGrow: 1,
  },
  tabsRoot: {
    backgroundColor: 'white',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
  },
  tabsIndicator: {
    backgroundColor: '#3071ad',
  },
  tabContentWrapper: {
    padding: 30,
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '& span': {
      fontSize: 13,
      fontWeight: 700,
      color: '#a0a0a0',
    },
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {
    '& span': {
      color: '#3071ad',
    }
  },
};

function Reports(props) {
  const {
    tabValue,
    handleChangeTab,
    translations,
    loadingBusinessDashboard,
    loadingCostExplorer,
    businessDashboardSettings,
    costExplorerSettings,
    showMobileInfo,
    closeMobileInfo,
    classes,
  } = props;

  let tabcontent = <div>{translations.comingSoon}</div>;
  
  if (businessDashboardSettings != null && tabValue === 0) {
    tabcontent = (
      loadingBusinessDashboard
      ? <CircularProgress />
      :(
        <div className={container}>
          <div className={innerContainer}>
            <PowerbiEmbedded
              id={businessDashboardSettings.id}
              embedUrl={businessDashboardSettings.url}
              accessToken={businessDashboardSettings.token}
              filterPaneEnabled={false}
              navContentPaneEnabled={false}
              pageName={businessDashboardSettings.reportName}
              permissions={7}
              tokenType={1}
              embedType={`${'report'}`}
              height='680px'
            />
          </div>
        </div>
      )
    )
  }

  if (costExplorerSettings != null && tabValue === 1) {
    tabcontent = (
      loadingCostExplorer
      ? <CircularProgress />
      : (
        <div className={container}>
          <div className={innerContainer}>
            <PowerbiEmbedded
              id={costExplorerSettings.id}
              embedUrl={costExplorerSettings.url}
              accessToken={costExplorerSettings.token}
              filterPaneEnabled={false}
              navContentPaneEnabled={false}
              pageName={costExplorerSettings.reportName}
              permissions={7}
              tokenType={1}
              embedType={`${'report'}`}
              height='680px'
            />
          </div>
        </div>
      )
    )
  }

  return (
    <div>
      <h1>{translations.reports}</h1>
      {!!showMobileInfo &&
        <Paper className={paper}>
          <div className={paperContent}><Info style={{width: 20, height: 20}} />{translations.mobileInfo}</div>
          <IconButton className={closeBtn} onClick={closeMobileInfo}><Close /></IconButton>
        </Paper>
      }
      <div className={classes.root}>
        <Tabs 
          value={tabValue} 
          onChange={handleChangeTab}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={translations.businessDashboard}
          />
          <Tab
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={translations.cloudCostExplorer}
          />
        </Tabs>
        <div className={classes.tabContentWrapper}>
          {tabcontent}
        </div>
      </div>
    </div>
  );
}

Reports.propTypes = {
  tabValue: PropTypes.number.isRequired,
  loadingBusinessDashboard: PropTypes.bool.isRequired,
  loadingCostExplorer: PropTypes.bool.isRequired,
  businessDashboardSettings: PropTypes.shape({
    id: PropTypes.string,
    token: PropTypes.string,
    url: PropTypes.string,
    reportName: PropTypes.string,
  }),
  costExplorerSettings: PropTypes.shape({
    id: PropTypes.string,
    token: PropTypes.string,
    url: PropTypes.string,
    reportName: PropTypes.string,
  }),
  closeMobileInfo: PropTypes.func.isRequired,
  showMobileInfo: PropTypes.bool.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  withTranslations,
)(Reports);
