export const formatDate = (date) => {
    const jsDate = new Date(date);
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();
  return `${year}-${addZero(month)}-${addZero(day)}`;
}

export const formatRenewalDate = (date) => {
    const jsDate = new Date(date);
    jsDate.setDate(jsDate.getDate() + 1);
    
    const year = jsDate.getFullYear();
    const month = jsDate.getMonth() + 1;
    const day = jsDate.getDate();
    
    return `${year}-${addZero(month)}-${addZero(day)}`;
}

export const validateEmail = (emails) => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const errors = emails
    .split(';')
    .filter(email => !!email)
    .filter(email => !re.test(email))
  return !errors.length;
}

export const passwordsError = (currentPassword, newPassword) => {
  if(currentPassword === newPassword) {
    return 'You can not change password to the same as the previous';
  }
  if(!!newPassword && newPassword.length < 6) {
     return 'The password needs to contain at least 6 characters';
  }
  return '';
}

export const queryStringToObject = (queryString) => {
  let pairs = queryString.split('&');
  let result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return result; 
}

export const generateRandomString = (size) => {
  let str = Math.random().toString(36).substring(size || 7);
  return str;
}

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

const addZero = (number) => {
  return `${number}`.length < 2 ? `0${number}` : `${number}`
}