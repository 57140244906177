import {
  FETCH_TRANSLATIONS,
  FETCH_TRANSLATIONS_SUCCESS,
  FETCH_TRANSLATIONS_ERROR,
  FETCH_SUPPORTED_LANGUAGES_ERROR,
  FETCH_SUPPORTED_LANGUAGES_SUCCESS,
} from './../../constants/actionConstants';

import { getTranslations, fetchSupportedLanguages } from './../../helpers/api';

function fetchTranslations() {
  return {
    type: FETCH_TRANSLATIONS,
  };
}

function fetchTranslationsSuccess(translations, currentLanguage) {
  return {
    type: FETCH_TRANSLATIONS_SUCCESS,
    translations,
    currentLanguage,
  }
}


function fetchTranslationsError() {
  return {
    type: FETCH_TRANSLATIONS_ERROR,
  }
}

function fetchSupportedLanguagesSuccess(supportedLanguages) {
  return {
    type: FETCH_SUPPORTED_LANGUAGES_SUCCESS,
    supportedLanguages,
  }
}

function fetchSupportedLanguagesError() {
  return {
    type: FETCH_SUPPORTED_LANGUAGES_ERROR,
  }
}

export function handleFetchTranslations(lang) {
  return (dispatch) => {
    dispatch(fetchTranslations());
    return getTranslations(lang)
      .then((translations) => dispatch(fetchTranslationsSuccess(translations, lang)))
      .catch(() => dispatch(fetchTranslationsError()));
  }
}

export function handleFetchSupportedLanguages() {
  return (dispatch) => {
    return fetchSupportedLanguages()
      .then((supportedLanguages) => dispatch(fetchSupportedLanguagesSuccess(supportedLanguages)))
      .catch(() => dispatch(fetchSupportedLanguagesError()));
  }
}
