import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withTranslations } from './../../hocs/translations';

import { SubscriptionContainer } from './../../containers';
import {
    container,
    expansionWrapper,
    TableHeaderText,
} from './Subscriptions.module.less';

import {
    tableHeaderOverride,
    expandIconToLeftOverride,
} from './SubscriptionsMaterialUiOverrides'

const styles = {
    tableHeaderOverride,
    expandIconToLeftOverride,
};

function Subscriptions(props) {
    const {
        subscriptions,
        error,
        isFetching,
        translations,
        classes,
    } = props;
    if (isFetching) return <CircularProgress />
    if (error) return <p>Something went wrong</p>
    return (
        <div className={container}>
            <h1>{translations.subscriptions}</h1>
            <div className={expansionWrapper}>
                <ExpansionPanel className={classes.tableHeaderOverride} disabled>
                    <ExpansionPanelSummary className={classes.expandIconToLeftOverride}>
                        <Grid container spacing={8}>
                            <Grid item xs={3}>
                                <span className={TableHeaderText}>{translations.product}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <span className={TableHeaderText}>{translations.name}</span>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <span className={TableHeaderText}>{translations.term}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={TableHeaderText}>{translations.billingCycle}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={TableHeaderText}>{translations.created}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span className={TableHeaderText}>{translations.renewal}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <span className={TableHeaderText}>{translations.quantity}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span style={{ paddingLeft: 135}} className={TableHeaderText}>{translations.state}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                </ExpansionPanel>
                {
                    subscriptions.map(subscription => (
                        <SubscriptionContainer key={subscription.id} subscription={subscription} />
                    ))
                }
            </div>
        </div>
    );
}

Subscriptions.propTypes = {
    subscriptions: PropTypes.array,
    error: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}

export default compose(
    withStyles(styles),
    withTranslations,
)(Subscriptions);