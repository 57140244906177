import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslations } from '../../hocs/translations';
import { Link } from 'react-router-dom';
import { Can, CustomButton } from '../'

import Grid from '@material-ui/core/Grid';

function ManageMFATab(props) {
    const {
        user,
        role,
        currentUserId,
        handleCancelEdit,
        handleResetMFA,
        actionButtonStyles,
        translations,
    } = props
    return (
        <Fragment>
            {
                currentUserId == user.id &&
                <Fragment>
                    <Grid container>
                        <h3>{translations.manageMFATab}</h3>
                    </Grid>
                    <Link to={{ pathname: "ManageTwoFA/" }} target="_blank">{translations.manageMFALink}</Link>
                </Fragment>
            }
            <Fragment>
                <Can
                    role={role}
                    perform="users:resetMFA"
                    data={{
                        userId: currentUserId,
                        editUserId: user.id,
                    }}
                    yes={() => (
                        <Fragment>
                            {currentUserId === user.id && <Fragment><br /><br /></Fragment>}
                            <Grid>
                                <h3>{translations.resetMFATitle}</h3>
                            </Grid>
                            <CustomButton onClick={handleResetMFA} style={{ minWidth: 110 }}>{translations.resetMFAButtonText}</CustomButton>
                        </Fragment>
                    )}
                />
                <div className={actionButtonStyles}>
                    <CustomButton onClick={handleCancelEdit} type="secondary">{translations.cancel}</CustomButton>
                </div>
            </Fragment>
        </Fragment>
    )
}

ManageMFATab.propTypes = {
    user: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
    currentUserId: PropTypes.string.isRequired,
    handleResetMFA: PropTypes.func.isRequired,
    handleCancelEdit: PropTypes.func.isRequired,
    actionButtonStyles: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
}

export default withTranslations(ManageMFATab);
