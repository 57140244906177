import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { withTranslations } from './../../hocs/translations';

import * as alertActionCreators from './../../store/alerts/alertsActions';
import * as subscriptionsActionCreators from './../../store/subscriptions/subscriptionsActions';
import { Addon } from './../../components';

class AddonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: `${props.addon.quantity}`,
      enableUpdate: false,
    }
  }
  static propTypes = {
    subscriptionId: PropTypes.string.isRequired,
    handleUpdateAddon: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    openErrorModal: PropTypes.func.isRequired,
    addon: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
  }

  handleUpdateQuantity = (quantity) => {
    const originalQuantity = this.props.addon.quantity;
    this.setState({
      quantity: quantity,
      enableUpdate: originalQuantity !== Number(quantity),
    });
  }

  submitUpdateQuantity = () => {
    const {
      handleUpdateAddon,
      addAlert,
      subscriptionId,
      addon,
    } = this.props;
    const quantity = Number(this.state.quantity);
    
    if(quantity < 1) {
      this.setState({
        quantity: `${addon.quantity}`,
        enableUpdate: false,
      });
      this.props.openErrorModal();
      return
    }

    handleUpdateAddon(subscriptionId, addon.id, quantity)
      .then(() => {
        this.setState({
          enableUpdate: false,
        });
        addAlert('success', this.props.translations.subscriptionSuccessAlert);
      })
      .catch(() => {
        addAlert('error', this.props.translations.subscriptionErrorAlert);
        const originalQuantity = this.props.addon.quantity;
        this.setState({
          quantity: originalQuantity,
          enableUpdate: false,
        });
      });
  }

  render() {
    const {
      addon
    } = this.props;
    const {
      quantity,
      enableUpdate,
    } = this.state;
    
    return (
      <Addon
        addon={addon}
        quantity={quantity}
        enableUpdate={enableUpdate}
        handleUpdateQuantity={this.handleUpdateQuantity}
        submitUpdateQuantity={this.submitUpdateQuantity}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    ...alertActionCreators,
    ...subscriptionsActionCreators,
  }, dispatch)
);

export default compose(
  connect(
    () => ({}),
    mapDispatchToProps,
  ),
  withTranslations,
)(AddonContainer);