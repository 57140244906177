export default {
  //common
  ok: 'Ok',
  cancel: 'Cancel',
  
  title: 'Control Panel',
  //header
  home: 'Home',
  subscriptions: 'Subscriptions',
  reports: 'Reports',
  users: 'Users',
  myProfile: 'My profile',
  signOut: 'Sign out',
  signIn: 'Sign in',

  //home
  partnerContact: 'Partner contact',
  welcome: 'Welcome!',

  //subscriptions
  subscription: 'Subscription',
  product: 'Product',
  productName: 'Product name',
  name: 'Name',
  cloudAccount: 'Cloud account',
  renewal: 'Renewal',
  renewalDate: 'Renewal date',
  quantity: 'Quantity',
  update: 'Update',
  active: 'Active',
  suspended: 'Suspended',
  suspendedTooltip: 'If you would like to activate the subscription, please contact your reseller.',
  id: 'Id',
  createdDate: 'Created date',
  created: 'Created',
  domain: 'Domain',
  microsoftAgreement: 'Microsoft customer agreement',
  readMicrosoftAgreement: 'Read Microsoft customer agreement',
  usageAndInvoiceDetails: 'Usage & invoice details',
  addOns: 'Add-ons',
  subscriptionSuccessAlert: 'The subscription was successfully updated.',
  subscriptionErrorAlert: 'Unable to update the subscription.',
  suspendModalText: 'If you would like to suspend the subscription, please contact your reseller.',
  suspendModalTitle: 'Unable to update the quantity',
  term: 'Term',
  billingCycle: 'Billing cycle',
  nceSubscription: 'New Commerce',

  user: 'User',
  firstName: 'First name',
  lastName: 'Last name',
  language: 'Language',
  inviteUser: 'Invite user',
  emailAddress: 'Email address',
  emailAddressHelper: 'Separate multiple email addresses using the semicolon (;)',
  inviteUserModalTitle: 'Invite user',
  inviteUserModalButton: 'Send invite',
  invalidEmail: 'Invalid email address',
  emailInvitedSuccessAlert: 'The user was successfully invited.',
  emailInvitedErrorAlert: 'Could not invite user, try again later.',
  emailAlreadyExistsErrorAlert: 'A user with that email address already exists in the system. Please use another email address for your user.',
  deleteUserSuccessAlert: 'The user was successfully deleted.',
  deleteUserErrorAlert: 'Could not delete user, try again later.',
  deleteAccountModalTitle: 'Delete account?',
  deleteAccountModalText: 'You’re about to delete the account with the email',
  deleteAccountModalButton: 'Delete account',
  
  userRoleLabel: 'Role',
  userRoleSelect: 'Role',
  adminRole: 'Administrator',
  userRole: 'User',
  
  editUserAccountSuccessAlert: 'The user was successfully updated.',
  editUserAccountErrorAlert: 'Failed updating the user.',
  editUserPasswordSuccessAlert: 'The password was successfully updated.',
  editUserPasswordErrorAlert: 'Failed updating the password.',
  noUserAlert: 'Could not find user',

  editUser: 'Edit user',
  accountTab: 'Account',
  passwordTab: 'Password',
  saveChanges: 'Save changes',
  currentPassword: 'Current password',
  newPassword: 'New password',
  invalidPasswordError: 'Current password is invalid',
  sendPasswordResetEmail:'Send reset password email',
  sendPasswordResetEmailSuccessAlert: 'A password reset link was sent.Click the link in the email to create a new password.',
  sendPasswordResetEmailErrorAlert: 'A password reset link was not sent.',
  sendPasswordResetEmailInstruction:'An email will be sent with reset password link.',
  businessDashboard: 'Business dashboard',
  cloudCostExplorer: 'Cloud Cost Explorer',
  manageMFATab: 'Multi-factor authentication',
  manageMFALink: 'Manage Multi-factor authentication for your user',
  resetMFATitle: 'Reset the Multi-factor authentication of the user account',
  resetMFAButtonText: 'Reset Multi-factor authentication',
  resetMFAModalText: 'Are you sure you want to reset MFA for account with the email',
  resetMFAModalButtonText: 'Reset Account MFA',
  resetMFASucess: 'Multifactor authentication has successfully been reset',

  signedOutTitle: 'Signed out',
  signedOutText: 'You have successfully signed out.',

  unauthorizedTitle: 'Unauthorized',
  unauthorizedText: 'You are not authorized to view this page.',

  notFoundTitle: 'Not found',
  notFoundText: 'There is no page found with this url.',

  mobileInfo: 'For best experience, we recommend that you visit this page on a desktop computer.',

  comingSoon: 'Coming soon',
  state: 'State',
}
