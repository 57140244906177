import {
  FETCH_PARTNER_INFO,
  FETCH_PARTNER_INFO_SUCCESS,
  FETCH_PARTNER_INFO_ERROR
} from './../../constants/actionConstants';

const initialState = {
  isFetching: false,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTNER_INFO:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_PARTNER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.partnerInfo
      }
    case FETCH_PARTNER_INFO_ERROR:
      return {
        ...initialState,
        isFetching: false,
        error: "Unable to get partner info."
      }
    default:
      return state;
  }
}
