import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AlertContainer from './AlertContainer';

class AlertsContainer extends Component {
  static propTypes = {
    alerts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })
    ),
  }

  static defaultProps = {
    alerts: [],
  }

  render() {
    const { alerts } = this.props;
    return (
      alerts.map((alert, i) => (
        <AlertContainer key={`alert-${alert.id}`} alert={alert} displayOrder={i} />
      ))
    );
  }
}

const mapStateToProps =  ({ alerts }) => ({
  alerts,
});

export default connect(
 mapStateToProps,
)(AlertsContainer);