import React from 'react';
import PropTypes from 'prop-types';

import { PageContainer } from './Page.module.less';

function Page({ children }) {
  return(
    <div className={PageContainer}>
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ])
}

export default Page;
