import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  DELETE_USER,
  APPLY_NEW_USER_INFO,
  SET_CURRENT_USER_ID,
  ASSIGN_USER_CREDENTIALS,
} from './../../constants/actionConstants';

const initialState = {
  isFetching: false,
  error: '',
  currentUserId: '',
  userCredentials: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.users
      }
    case FETCH_USERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: "Unable to get users."
      }
    case DELETE_USER:
      return {
        ...state,
        items: state.items.filter(user => (
          user.id !== action.id
        ))
      }
    case APPLY_NEW_USER_INFO:
      return {
        ...state,
        items: !!state.items ? state.items.map(user => {
          if(user.id === action.id) {
            return {
              ...user,
              ...action.userInfo,
            }
          }
          return user;
        }) : [],
      }
    case SET_CURRENT_USER_ID:
      return {
        ...state,
        currentUserId: action.id
      }
    case ASSIGN_USER_CREDENTIALS:
      return {
        ...state,
        userCredentials: action.userCredentials
      }
    default:
      return state;
  }
}
