import React, { Component } from 'react';
import { connect } from 'react-redux';

export function withTranslations(WrappedComponent) {
  // ...and returns another component...
  class TranslatedComp extends Component {
    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <WrappedComponent {...this.props} />;
    }
  };
  const mapStateToProps = ({localization}) => ({
    translations: localization.translations,
  });
  return connect(
    mapStateToProps,
  )(TranslatedComp)
}