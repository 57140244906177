import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import { withStyles } from '@material-ui/core/styles';
import { withTranslations } from './../../hocs/translations';
import {
  partnerContact,
  partnerContactGrid
} from './Home.module.less';

const styles = {
  paperOverride: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
  }
}
function Home(props) {
  const { isFetching, error, text, contact, translations, classes } = props;
  if (isFetching) return <CircularProgress />
  if (error) return <p>{error}</p>
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item md={7} sm={6} xs={12}>
            <div dangerouslySetInnerHTML={{__html: text}}></div>
          </Grid>
          <Grid item md={5} sm={6} xs={12} className={partnerContactGrid}>
            <Paper className={`${partnerContact} ${classes.paperOverride}`}>
              <h3>{translations.partnerContact}</h3>
              <div><Phone style={{width: 20, height: 20}} /><a href={`tel:${contact.phone}`}>{contact.phone}</a></div>
              <div><Email style={{width: 20, height: 20}} /><a href={`mailto:${contact.email}`}>{contact.email}</a></div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Home.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  contact: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  translations: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withTranslations,
)(Home);