import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import  * as alertsActionCreators from './../../store/alerts/alertsActions';
import { Alert } from './../../components';

class AlertContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  static propTypes = {
    alert: PropTypes.shape({
      message: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    displayOrder: PropTypes.number.isRequired,
    removeAlert: PropTypes.func.isRequired,
  }
  static defaultProps = {
    alert: {
      type: '',
      message: '',
      id: ''
    }
  }

  componentDidMount(){
    if(this.props.alert){
      this.setState({ isOpen: true });   
    }
  }

  handleClose = (e, reason) => {
    if(reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false });
  };

  handleExited = () => {
    const {
      removeAlert,
      alert,
    } = this.props;
    removeAlert(alert.id)
  }

  render() {
    const {
      type,
      message
    } = this.props.alert;
    return (
      <Alert
        isOpen={this.state.isOpen}
        handleClose={this.handleClose} 
        alertType={type}
        alertMessage={message}
        displayOrder={this.props.displayOrder}
        handleExited={this.handleExited}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(alertsActionCreators, dispatch)
); 

export default connect(
  () => ({}),
  mapDispatchToProps, 
)(AlertContainer);
