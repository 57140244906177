import React from 'react';
import PropTypes from 'prop-types';

import { withTranslations } from './../../hocs/translations';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CustomButton,
} from '../../components';

function EditPasswordTab(props) {
  const {
    submitPasswordTab,
    handleCancelEdit,
    actionButtonStyles,
    loadingSavePassword,
    translations,
  } = props
  return (
    <div>
      <Grid container>
       <h3>{translations.sendPasswordResetEmailInstruction}</h3>
      </Grid>
      <div className={actionButtonStyles}>
        <CustomButton onClick={submitPasswordTab} disabled={loadingSavePassword} style={{minWidth: 110}}>
          {
            loadingSavePassword
            ? <CircularProgress style={{width: 20, height: 20}} />
            : translations.sendPasswordResetEmail
          }
        </CustomButton>
        <CustomButton onClick={handleCancelEdit} disabled={loadingSavePassword} type="secondary">{translations.cancel}</CustomButton>
      </div>
    </div>
  )
}

EditPasswordTab.propTypes = {
  actionButtonStyles: PropTypes.string.isRequired,
  submitPasswordTab: PropTypes.func.isRequired,
  handleCancelEdit: PropTypes.func.isRequired,
  loadingSavePassword: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired,
}

export default withTranslations(EditPasswordTab);
