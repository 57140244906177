import axios from 'axios';
import store from './../store/configureStore';
import { goToSignIn } from './auth';

const getHeaders = () => {
  const { access_token } = store.getState().users.userCredentials; 
  return {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'Authorization': `Bearer ${access_token}`
  }
};

const getUrl = (url) => `/api/${store.getState().users.userCredentials.consumer_id}${url}`;

axios.interceptors.response.use(res => res, (error) => {
  if(error.response.status === 401) {
    //TODO: get new token with refresh_token and do the request again with new token
    goToSignIn('/');
  }
  return Promise.reject(error);
});

export const get = (url) =>
  axios.get(getUrl(url), { headers: getHeaders()});

export const put = (url, ...data) =>
  axios.put(getUrl(url), ...data, { headers: getHeaders() });

export const post = (url, ...data) =>
  axios.post(getUrl(url), ...data, { headers: getHeaders() });

export const delete_ = (url, ...data) =>
  axios.delete(getUrl(url), ...data, { headers: getHeaders() });

export const getImage = (url) => {
  const { access_token } = store.getState().users.userCredentials; 
  return axios.get(getUrl(url), { headers: { 'Authorization': `Bearer ${access_token}` }, responseType: 'blob' })
    .then((response) => {
      return new Promise((resolve) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = function() {
          var imageDataUrl = reader.result;
          resolve(imageDataUrl);
        }
      });
    })
  };

export const fetchConfig = () =>
  axios.get('/api/configurations').then((res) => res.data)
