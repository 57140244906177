import {
  FETCH_CONFIG,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_ERROR,
} from './../../constants/actionConstants';

import { fetchConfig as fetchConfigApi }  from './../../helpers/apiService';

function fetchConfig() {
  return {
    type: FETCH_CONFIG,
  }
}

function fetchConfigSuccess(config) {
  return {
    type: FETCH_CONFIG_SUCCESS,
    config,
  }
}
function fetchConfigError() {
  return {
    type: FETCH_CONFIG_ERROR,
  }
}

export function handleFetchConfig() {
  return (dispatch) => {
    dispatch(fetchConfig());
    return fetchConfigApi()
      .then((config) => dispatch(fetchConfigSuccess(config)))
      .catch(() => dispatch(fetchConfigError()));
  }
}
