import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import {
  message
} from './Alert.module.less';

const styles = {
  snackBarOverride: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
    flexWrap: 'nowrap',
  },
  error: {
    color: '#c13b43',
    height: '20px',
    width: '20px',
    verticalAlign: "middle"
  },
  success: {
    color: '#84b74f',
    height: '20px',
    width: '20px',
    verticalAlign: "middle"
  }
}

function Alert(props) {
  const {
    classes,
    isOpen,
    handleClose,
    handleExited,
    alertMessage,
    alertType,
    displayOrder,
  } = props;

  const successAlert = alertType === 'success';
  return (
    <Snackbar
      style={{
        bottom: (24 + (displayOrder*(60+5))),
        transition: 'bottom 100ms',

      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={6000}
      open={isOpen}
      onClose={handleClose}
      onExited={handleExited}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
    >
      <SnackbarContent
        className={classes.snackBarOverride}
        message={
          [
            !successAlert
            ? <ErrorIcon style={{width: 20, height: 20}} key="alert-icon" className={classes.error} />
            : <CheckCircleIcon style={{width: 20, height: 20}}  key="alert-icon" className={classes.success}/>,
            <span key="alert-message" id="message-id" className={message}>
              {alertMessage}
            </span>
          ]
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon style={{width: 20, height: 20}} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

Alert.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  alertType: PropTypes.string.isRequired,
  alertMessage: PropTypes.string.isRequired,
  displayOrder: PropTypes.number.isRequired,
  handleExited: PropTypes.func.isRequired,
};

export default withStyles(styles)(Alert);