import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as configActionCreators from './../../store/config/configActions';
import { Route, withRouter, Switch } from 'react-router-dom';
import { isAuthed, goToSignIn, hasToken } from './../../helpers/auth';
import ReactAI from 'react-appinsights';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import './../../styles/global.less';

import {
  Page,
  Unauthorized,
  Logout,
  NotFound
} from '../../components';

import {
  HomeContainer,
  HeaderContainer,
  SubscriptionsContainer,
  AlertsContainer,
  UsersContainer,
  EditUserContainer,
  ReportsContainer,
  CallbackContainer,
} from '../../containers';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if(!hasToken()) {
      goToSignIn(rest.location.pathname);
      return <CircularProgress />
    }
    if(isAuthed()) {
      return <Component {...props} />
    }
    return <Unauthorized />
  }} />
)

class MainContainer extends Component {
  static propTypes = {
    configError: PropTypes.string.isRequired,
    fetchingConfig: PropTypes.bool.isRequired,
    handleFetchConfig: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.handleFetchConfig()
      .then(({ config }) => {
        ReactAI.init({instrumentationKey: config ? config.instrumentationKey : null}, this.props.history)
      });
  }

  render() {
    const { configError, fetchingConfig } = this.props;
    if(fetchingConfig) return <CircularProgress />
    if(configError) return 'Error fetching config'
    return (
      <div>
        <CssBaseline  />
        <HeaderContainer isAuthorized={isAuthed()} />
        <Page>
          <Switch>
            <PrivateRoute exact path="/" component={HomeContainer} />
            <PrivateRoute path="/subscriptions" component={SubscriptionsContainer} />
            {/* <PrivateRoute path="/reports" component={ReportsContainer} /> */}
            <PrivateRoute exact path="/users" component={UsersContainer} />
            <PrivateRoute exact path="/users/:id" component={EditUserContainer} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/callback" component={CallbackContainer} />
            <PrivateRoute component={NotFound} />
          </Switch>
        </Page>
        <AlertsContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ config }) => ({
  configError: config.error,
  fetchingConfig: config.isFetching,
});

const mapDispatchToProps = (dispatch) => 
  bindActionCreators(configActionCreators, dispatch);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(MainContainer)
